import {useEffect, useReducer} from "react";
import axios from "axios";
import {toggleDataSet} from '../../util/charts'
import SecActivityCountsWrapper from "../../util/SecActivityCountsWrapper";
import useRegisteredUser from "./useRegisteredUser";

const reducer = (state, action) => {
  switch (action.type) {
    case 'INIT':
      return {
        ...state,
        isLoading: true,
        __forceRefresh: false
      };
    case 'SUCCESS':
      return {
        ...state,
        isLoading: false,
        fulfilled: true,
        items: action.payload,
        __forceRefresh: false
      };
    case 'FAILURE':
      return {
        ...state,
        isLoading: false,
        isError: true,
        __forceRefresh: false
      };
    case 'REFRESH':
      return {
        isLoading: true,
        isError: false,
        fulfilled: false,
        items: [],
        startDate: action.payload.startDate,
        endDate: action.payload.endDate,
        __forceRefresh: true
      };
    case 'TOGGLE':
      toggleDataSet(state.items, action.payload)
      return {
        ...state
      }
  }
};

export default function useSecActivityCounts(labels, interval) {
  const user = useRegisteredUser();
  const [state, dispatch] = useReducer(reducer, {
    isLoading: true,
    isError: false,
    fulfilled: false,
    items: [],
    startDate: interval.startDate,
    endDate: interval.endDate,
    __forceRefresh: false
  });

  useEffect(() => {
    if (state.__forceRefresh) {
      (async () => {
        dispatch({type: 'INIT'});
        try {
          const result = await axios.post(`/data-api/companies/${user.getCompany().uuid}/activity`,
            {
              from: state.startDate,
              to: state.endDate,
              timezone: user.timezone,
              labels: (labels) ? labels.map(label => label.value) : []
            },
            {
              headers: user.authenticationHeaders,
            }
          );
          dispatch({
            type: 'SUCCESS',
            payload: SecActivityCountsWrapper(result.data.activity, {
              startDate: state.startDate,
              endDate: state.endDate
            })
          })
        } catch (e) {
          dispatch({type: 'FAILURE'})
        }
      })()
    }
  }, [user.getCompany().uuid, labels, state.__forceRefresh]);

  return [{...state},
    (startDate, endDate) => dispatch({type: 'REFRESH', payload: {startDate, endDate}}),
    (dataSetLabel, disabled) => dispatch({type: 'TOGGLE', payload: {dataSetLabel: dataSetLabel, hidden: disabled}})
  ]
}
