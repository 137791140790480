import axios from "axios";

export const AWSApi = (user, awsUuid) => {
  if (user.isCustomerManager || !awsUuid || user.is2FA) {
    return {...user, awsSubscriptionSuccess: false};
  }

  return axios.put(
    `/api/v1/companies/${user.company.uuid}/link-aws-subscription`,
    {
      company: {
        aws_subscription_uuid: awsUuid
      },
    },
    {
      headers: {"Authorization": `Bearer ${user.token}`}
    }
  )
    .then(r => ({...user, awsSubscriptionSuccess: true, statusCode: 200}))
    .catch(r => ({...user, awsSubscriptionSuccess: false, statusCode: r.response.status}))
}
