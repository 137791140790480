import isIP from "is-ip";
import isCidr from "is-cidr";
import {compareCIDR} from "ip6addr";

export const validateIP = (value, limitMask = false) => {
  let isValid = true, errorMsg = '';

  let inputValue = value.trim();
  const isIp = isIP(inputValue);

  if (isIp) {
    isValid = isIp;
    errorMsg = '';
  } else if (isCidr.v4(inputValue)) {
    if (limitMask) {
      let v4 = inputValue.split('/');
      isValid = (v4[1] > 28 && v4[1] < 33);
      errorMsg = (v4[1] > 28 && v4[1] < 33) ? '' : 'Mask value must be between 29 and 32';
    }
  } else if (isCidr.v6(inputValue)) {
    if (limitMask) {
      let v6 = inputValue.split('/');
      isValid = (v6[1] > 57 && v6[1] < 129);
      errorMsg = (v6[1] > 28 && v6[1] < 33) ? '' : 'Mask value must be between 58 and 128';
    }
  } else {
    isValid = false;
    errorMsg = 'Please enter a valid CIDR or IP address';
  }

  return {
    isValid: Boolean(isValid), errorMsg
  }
}

export const validateUniqueIp = (rulesArray) => {
  const ipList = rulesArray.map(rule => rule.ip);
  const uniqueness = uniqueIp(ipList);
  return rulesArray.map((rule, idx) => {
    const validation = validateIP(rule.ip);
    let errorMsg = validation.errorMsg;
    if (validation.isValid) {
      errorMsg = uniqueness[idx];
    }
    return errorMsg;
  })
}

export const validateInputLength = (value, min, max = null, fieldName) => {
  const descriptionLength = value?.trim().length ?? 0;
  let isInvalid = !(value && max ? descriptionLength >= min && descriptionLength <= max : descriptionLength >= min);
  let errorMsg = "";

  if (max) {
    if (descriptionLength < min) {
      errorMsg = `${fieldName} is too short (minimum is ${min} characters)`;
    }

    if (descriptionLength > max) {
      errorMsg = `${fieldName} is too long (maximum is ${max} characters)`;
    }
  } else {
    errorMsg = `Please complete ${fieldName}`;
  }

  return {
    isInvalid,
    errorMsg,
  };
};

export const validateURL = (value, fieldName) => {
  const url_pattern = /^https?:\/\/[a-zA-Z0-9-]+((\.[a-zA-Z0-9-]+)*\.\w{2,})+(:\d{0,4})?(\/[\w=.?]+)*\/?$/;
  if (!value.match(url_pattern)) {
    return {isInvalid: true, errorMsg: `Please enter a valid ${fieldName}`};
  } else {
    return {
      isInvalid: false,
      errorMsg: "",
    };
  }
};

export const isValidUrl = (value, fieldName) => {
  try {
    new URL(value);
    return {isInvalid: false, errorMsg: "",};
  } catch (err) {
    console.log({err})
    return {isInvalid: true, errorMsg: `Please enter a valid ${fieldName}`};
  }
}

const uniqueIp = (ipList) => {
  const addSubnetMask = (ip) => {
    if (isIP.v4(ip)) {
      ip = `${ip}/32`;
    } else {
      ip = `${ip}/128`;
    }
    return ip;
  }

  const compare = (ip, idx, list) => {
    return list.reduce((acc, item, index) => {
      if (acc) {
        try {
          acc = !(idx !== index && compareCIDR(ip, item) === 0);
        } catch (e) {
          acc = true;
        }
      }
      return acc;
    }, true)
  }

  const listWithMask = ipList.map(ip => {
    const currentHasMask = ip ? ip.split('/').length === 2 : true;
    if (!currentHasMask) {
      ip = addSubnetMask(ip);
    }
    return ip;
  });

  return listWithMask.map((ip, idx, list) => {
    const comparison = compare(ip, idx, list);
    return comparison ? '' : 'CIDR/IP subnet cannot be repeated';
  })
}

export const validateEmail = (val) => {
  const emailPattern = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  return emailPattern.test(val);
}

export const validateWeakPassword = (value) => {
  const regularExpression = /^(?=.*[0-9])(?=.*[!_+@#$%^&*-])(?=.*[a-zA-Z]).{6,}$/;

  if (!value.match(regularExpression)) {
    return {
      isInvalid: true,
      errorMsg: "validate_password"
    };
  } else {
    return {
      isInvalid: false,
      errorMsg: "",
    };
  }
};

export const validatePassword = (password, passwordConfirmation, modified) => {
  let errorMsg = '', invalidPass = false, invalidConfPass = false;

  if (!modified) {
    invalidPass = password.length === 0;
    invalidConfPass = passwordConfirmation.length === 0;

    if (invalidPass || invalidConfPass) {
      errorMsg = 'Please enter a password';
    } else if (password.length > 128 || passwordConfirmation.length > 128) {
      errorMsg = 'Password is too long (maximum is 128 characters)';
      invalidPass = password.length > 128;
      invalidConfPass = passwordConfirmation.length;
    } else if (password !== passwordConfirmation) {
      invalidConfPass = true;
      errorMsg = 'The entered passwords don\'t match';
    } else {
      const validation = validateWeakPassword(password);
      invalidPass = validation.isInvalid;
      invalidConfPass = validation.isInvalid;
      errorMsg = validation.errorMsg;
    }
  }

  return {errorMsg, invalidPass, invalidConfPass}
}

export const validatePort = (value, min, max, fieldName, optionalMsg) => {
  const isInt = /^[0-9]+$/.test(value);
  let isInvalid, errorMsg

  if (isInt) {
    const port = parseInt(value);
    isInvalid = !(port >= min && port <= max && !isNaN(port));
    errorMsg = optionalMsg ? optionalMsg : `Please enter a valid ${fieldName}`;
  } else {
    isInvalid = true
    errorMsg = `Please enter a valid ${fieldName}`;
  }


  return {
    isInvalid,
    errorMsg,
  };
}
