import React, {lazy, Suspense} from "react";
import {Redirect, Route, Switch} from 'react-router-dom';
import LoginForm from "./LoginForm";
import LoginFormSSO from "./LoginFormSSO";
import {ResetPasswordForm} from "./ResetPasswordForm";
import LoginLayout from "./LoginLayout";
import MspRedirection from "./MspRedirection";
import Validation2FA from "./Validation2FA";
import Backup2FA from "./Backup2FA";
import {LoginProvider} from "../../util/context/LoginContext";
import {AWSLogin} from "./aws/AWSLogin";
import {SimpleLoading} from "../../util/loading";

const SignUp = lazy(() => import('./SignUp'));
const RegisterSuccess = lazy(() => import('./RegisterSuccess'));
const AcceptInvitation = lazy(() => import('./AcceptInvitation'));
const RecoverForm = lazy(() => import('./RecoverForm'));

const AccountSectionComponent = () => {
  return (
    <LoginProvider>
      <Suspense fallback={<SimpleLoading/>}>
        <Switch>
          <Route path="/account/aws-login" component={AWSLogin}/>
          <Route path="/account/sign-up/success" component={RegisterSuccess}/>
          <Route path="/account/sign-up" component={SignUp}/>
          <Route path="/account/msp-redirection" component={MspRedirection}/>
          <Route path="/account/login" component={LoginForm} exact/>
          <Route path="/account/*">
            <LoginLayout>
              <Switch>
                <Route path="/account/login/sso" component={LoginFormSSO} exact/>
                <Route path="/account/login/2fa" component={Validation2FA} exact/>
                <Route path="/account/login/backup-code" component={Backup2FA} exact/>
                <Route path="/account/recover" component={RecoverForm}/>
                <Route path="/account/reset-password" component={ResetPasswordForm}/>
                <Route path="/account/accept-invitation" component={AcceptInvitation}/>
                <Route path="/account" render={() => <Redirect to={"/account/login"}/>}/>
              </Switch>
            </LoginLayout>
          </Route>
          <Route path="/account" render={() => <Redirect to={"/account/login"}/>}/>
        </Switch>
      </Suspense>
    </LoginProvider>
  );
};

export default AccountSectionComponent;
