import {Route, Switch} from "react-router-dom";
import React, {useState} from "react";
import {DoItLaterHeader} from "./DoItLaterHeader";
import {Col, Container, Label, Row} from "reactstrap";
import {useTranslation} from "react-i18next";
import classNames from "classnames";
import {HelpCard} from "./HelpCard";
import tutorialRoutes from "../../tutorial_routes";
import {meetLink} from "../../util/MeetLumuFree";
import useRegisteredUser from "../../api/hooks/useRegisteredUser";

const Tutorial = ({history, location}) => {
  const pathname = location.pathname;
  const {t} = useTranslation();
  const [step, setStep] = useState(1);
  const user = useRegisteredUser(null);

  if (!user.show_tutorial) {
    history.push('/dashboard');
  }

  const getDocs = (pathname) => {
    switch (pathname) {
      case '/start/gateways' || '/advance-tutorial/gateways':
        return {id: '420518000000925003', url: 'https://docs.lumu.io/portal/en/kb/articles/add-a-public-gateway'}
      case '/start/appliance-dns' || '/advance-tutorial/appliance-dns':
        return {id: '420518000000806089', url: 'https://docs.lumu.io/portal/en/kb/articles/lumu-virtual-appliance'}
      case '/start/appliance-firewall' || '/advance-tutorial/appliance-firewall':
        return {id: '420518000000806089', url: 'https://docs.lumu.io/portal/en/kb/articles/lumu-virtual-appliance'}
      case '/start/appliance-proxy' || '/advance-tutorial/appliance-proxy':
        return {id: '420518000000806089', url: 'https://docs.lumu.io/portal/en/kb/articles/lumu-virtual-appliance'}
      default:
        return {id: '', url: ''}
    }
  }

  return (
    <div>
      <DoItLaterHeader history={history}/>
      <div className="tutorial-height bg-gray-f2">
        <Container fluid className="pt-5">
          <Row className="mt-4 justify-content-center align-items-center">
            <Col md={8}>
              <div className="d-flex align-items-center mb-3">
                <h2 className="d-flex mb-0">
                  <div className={classNames("font-weight-bold text-center rounded w-35px rounded-circle mr-2", {
                    "bg-black text-white": step === 1,
                    "bg-gray-c4 fnt-darkgray": step !== 1
                  })}>
                    1
                  </div>
                  <div className={classNames("font-weight-bold", {"fnt-darkgray": step !== 1})}>{t('Create')}</div>
                </h2>
                <i className="fa fa-chevron-right mx-3" aria-hidden="true"/>
                <h2 className="d-flex mb-0">
                  <div className={classNames("font-weight-bold text-center rounded w-35px rounded-circle mr-2", {
                    "bg-black text-white": step === 2,
                    "bg-gray-c4 fnt-darkgray": step !== 2
                  })}>
                    2
                  </div>
                  <div className={classNames("font-weight-bold", {"fnt-darkgray": step !== 2})}>
                    {t('Configure')}
                  </div>
                </h2>
              </div>
              <Switch>
                {tutorialRoutes.map((route, idx) => {
                  return route.component ? (
                    <Route path={route.path} exact={route.exact} render={props => (
                      <route.component key={route.path}  {...props} step={step} setStep={setStep}/>
                    )}/>
                  ) : null
                })}
              </Switch>
              <hr/>
              <p>
                {t('In case you have doubts on how to create or configure your network metadata collectors, the following assets might help you out')}
              </p>
              <Label className="font-weight-bold">
                {t('Get help')}
              </Label>
              <Row className='ml-0'>
                <HelpCard icon={'fa-book'} title={'Learn in details'} subtitle={'Documentation'}
                          id={getDocs(pathname).id} url={getDocs(pathname).url} className='mr-2'/>
                <HelpCard icon={'fa-calendar'} title={'Talk to an expert'} subtitle={'Schedule a call'}
                          url={meetLink(user)}/>
              </Row>
            </Col>
          </Row>
        </Container>
      </div>
    </div>
  )
}

export default Tutorial;
