import React from "react";

export const SimpleLoading = () => {
  return (
    <div className="d-flex justify-content-center">
      <div className="animated fadeIn pt-3 text-center">
        <div className="sk-spinner sk-spinner-pulse"/>
      </div>
    </div>
  )
}

export const SpinnerDot = ({className = ''}) => (
  <div className={`sk-fading-circle ${className}`}>
    <div className="sk-circle1 sk-circle"/>
    <div className="sk-circle2 sk-circle"/>
    <div className="sk-circle3 sk-circle"/>
    <div className="sk-circle4 sk-circle"/>
    <div className="sk-circle5 sk-circle"/>
    <div className="sk-circle6 sk-circle"/>
    <div className="sk-circle7 sk-circle"/>
    <div className="sk-circle8 sk-circle"/>
    <div className="sk-circle9 sk-circle"/>
    <div className="sk-circle10 sk-circle"/>
    <div className="sk-circle11 sk-circle"/>
    <div className="sk-circle12 sk-circle"/>
  </div>
)

