import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import HttpApi from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';
// not like to use this?
// have a look at the Quick start guide
// for passing in lng and translations on init
const translationFilename = process.env.REACT_APP_TRANSLATION_FILE_NAME || 'translation.json';
const isDevMode = !process.env.NODE_ENV || process.env.NODE_ENV === 'development';

i18n
  // load translation using xhr -> see /public/locales
  // learn more: https://github.com/i18next/i18next-http-backend
  .use(HttpApi)
  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  .use(LanguageDetector)
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    fallbackLng: 'en',
    debug: isDevMode,

    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
    backend : {
      loadPath: '/locales/{{lng}}/' + translationFilename,
      crossDomain: false
    },

    // allow keys to be phrases having `:`, `.`
    nsSeparator: false,
    keySeparator: false,
  });


export default i18n;
