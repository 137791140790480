import {useEffect, useReducer} from "react";
import axios from "axios";

const reducer = (state, action) => {
  switch (action.type) {
    case 'INIT':
      return {
        ...state,
        isLoading: true,
        isError: false,
        fulfilled: false,
        __forceRefresh: false
      }

    case 'SUCCESS':
      return {
        ...state,
        isLoading: false,
        isError: false,
        fulfilled: true,
        info: action.payload,
        __forceRefresh: false
      }

    case 'FAILURE':
      return {
        ...state,
        isLoading: false,
        isError: true,
        fulfilled: false,
        __forceRefresh: false
      }

    default:
      return {
        ...state
      }
  }
}

export const useGeolocate = () => {
  const [state, dispatch] = useReducer(reducer, {
    isLoading: false,
    isError: false,
    fulfilled: false,
    info: null,
    __forceRefresh: true
  });

  useEffect(() => {
    if (state.__forceRefresh) {
      (async () => {
        dispatch({type: 'INIT'})
        try {
          const result = await axios.get(`/api/v1/geolocation`);
          dispatch({type: 'SUCCESS', payload: result.data})
        } catch (e) {
          dispatch({type: 'FAILURE'})
        }
      })()
    }
  }, [state.__forceRefresh]);

  return {...state}
}

export const geolocateApi = () => axios.get(`/api/v1/geolocation`);
