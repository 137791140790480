import React from 'react';
import {Badge, Button} from "reactstrap";
import classNames from 'classnames'
import {useTranslation} from "react-i18next";
import useMedia from "./useMedia";

const color = (type) => {
  let color;
  switch (type) {
    case 'Malware':
    case 'DGA':
      color = 'yellow';
      break;
    case 'Phishing':
      color = 'blue';
      break;
    case 'C2C':
      color = 'orange';
      break;
    case 'Network Scan':
      color = 'green';
      break;
    case 'High':
      color = 'primary';
      break;
    case 'Medium':
      color = 'light-yellow';
      break;
    case 'Low':
      color = 'extended-secondary';
      break;
    default:
      color = 'gray';
  }

  return color;
};

const crowdStrikeColor = (type) => {
  let color;
  switch (type) {
    case 'Malware':
      color = 'yellow';
      break;
    case 'Phishing':
      color = 'blue';
      break;
    case 'C2C':
      color = 'orange';
      break;
    case 'Critical':
      color = 'crowdStrike-critical';
      break;
    case 'High':
      color = 'crowdStrike-high';
      break;
    case 'Medium':
      color = 'crowdStrike-medium';
      break;
    case 'Low':
      color = 'crowdStrike-low';
      break;
    case 'Informational':
      color = 'crowdStrike-informational';
      break;
    default:
      color = 'gray';
  }

  return color;
};

const toastColor = (type) => {
  let color;
  switch (type) {
    case 'Malware':
    case 'DGA':
      color = 'yellow';
      break;
    case 'Phishing':
      color = 'blue';
      break;
    case 'C2C':
      color = 'orange';
      break;
    case 'Network Scan':
      color = 'green';
      break;
    case 'High':
      color = 'primary';
      break;
    case 'Medium':
      color = 'yellow';
      break;
    case 'Low':
      color = 'extended-secondary';
      break;
    default:
      color = 'dark-light';
  }

  return color;
};

const BusinessRelevance = [{name: 'Low', value: 1}, {name: 'Medium', value: 2}, {name: 'High', value: 3}];

export const ThreatTypeBadges = ({type = [], removeDuplicates = true, name = null}) => {
  let uniqueTypes = [...type];
  const {t} = useTranslation();
  if (removeDuplicates) {
    uniqueTypes = [...new Set(type)];
  }
  const categories = [];
  for (let i = 0; i < type.length; i++) {
    categories.push(
      <div className="d-flex align-items-center" key={i}>
        <Badge className={`text-white mr-1 rounded bg-${color(uniqueTypes[i])}`}>
          {t(uniqueTypes[i])}
        </Badge>
        <span>{name}</span>
      </div>
    );
  }
  return (
    categories
  )
};

export const ToastBadges = ({type = [], removeDuplicates = true, name = null}) => {
  const {t} = useTranslation();
  let uniqueTypes = [...type];
  if (removeDuplicates) {
    uniqueTypes = [...new Set(type)];
  }
  const categories = [];
  for (let i = 0; i < type.length; i++) {
    categories.push(
      <div className="d-flex align-items-center" key={i}>
        <Badge className={`text-white mr-1 rounded bg-${toastColor(uniqueTypes[i])}`}>
          {t(uniqueTypes[i])}
        </Badge>
        <span>{name}</span>
      </div>
    );
  }
  return (
    categories
  )
};

export const CrowdStrikeBadges = ({type = []}) => {
  const {t} = useTranslation();
  let uniqueTypes = [...type];
  const badges = [];
  for (let i = 0; i < type.length; i++) {
    badges.push(
      <div key={i} className="mb-2">
        <Badge className={`text-white rounded-left bg-${color(uniqueTypes[i].type)}`}>
          {t(uniqueTypes[i].type)}
        </Badge>
        <Badge
          className={`text-white mr-1 rounded-right bg-${crowdStrikeColor(`${uniqueTypes[i].severity.charAt(0).toUpperCase()}${uniqueTypes[i].severity.slice(1)}`)}`}>
          {`${uniqueTypes[i].severity.charAt(0).toUpperCase()}${uniqueTypes[i].severity.slice(1)}`}
        </Badge>
      </div>
    );
  }
  return (
    badges
  )
}

export const ThreatTypeColors = ({type}) => {
  const categories = [];
  type.forEach((v, i) => {
    categories.push(
      <span className="d-flex" key={i}>
          <Badge className={`w-20px text-white mr-1 rounded bg-${color(v)}`}>
            <span>{` `}</span>
          </Badge>
        </span>
    );
  });

  return (
    categories
  );
};

export const SpamboxBadge = ({isSpambox}) => {
  return (
    <span className={classNames({"d-none": (isSpambox && !isSpambox.length)})}>
      <Badge className={`text-white mr-1 rounded bg-lu-darkgray`}>
        <span className="">Spambox</span>
      </Badge>
    </span>
  )
};

export const LabelBadge = ({lbl}) => {
  return (
    <Badge className='text-dark px-2 py-0 rounded bg-light-gray'>
      <span className="font-weight-normal line-height-21px fnt-14">{lbl}</span>
    </Badge>
  )
};

export const AdvanceModeBadge = ({type, id}) => {
  const isNetflow = type[0] === 'Netflow';
  return (
    type.map(() =>
      <div key={`${id}`}>
        <Badge className={classNames("mr-1 rounded advance-mode-badge", {
          "bg-gray-100": isNetflow,
          "bg-gray-200": !isNetflow
        })}>
          <span className="fon">{type}</span>
        </Badge>
      </div>
    )
  )
};

export const LabelRelevanceBadge = ({currentRelevance, column = false}) => {
  const {t} = useTranslation();
  const nameArr = [t('low'), t('medium'), t('high')]
  const relevanceBadges = [];
  const labelRelevance = currentRelevance >= 0 ? [nameArr[currentRelevance]] : [...nameArr];

  labelRelevance.forEach((val, idx) => {
    const backgroundColor = `bg-${color(val)}`;
    relevanceBadges.push(
      <Badge key={idx} className={classNames(`w-75px text-white mr-1 rounded ${backgroundColor}`, {"mb-1": column})}>
        {val}
      </Badge>
    )
  });

  return (
    <div className={classNames("d-flex", {"flex-column": column})}>{relevanceBadges}</div>
  );
};

export const LabelRelevanceColor = ({relevance = 1, hasName}) => {
  const name = BusinessRelevance.find(v => v.value === relevance)?.name;
  const backgroundColor = `bg-${color(name)}`;

  return (
    <Badge id="label-relevance-title"
           className={hasName ? `w-75px text-white mr-1 rounded ${backgroundColor}` : `w-50px rounded ${backgroundColor} align-text-top py-1`}>
      {hasName ? name : ` `}
    </Badge>
  );
};

export const LabelRelevanceButtons = ({relevance, setRelevance}) => {
  const isMobile = useMedia("(max-width: 576px)");
  const relevanceButtons = [];
  BusinessRelevance.forEach(({name, value}, idx) => {
    let color;
    switch (name) {
      case 'High':
        color = 'primary';
        break;
      case 'Medium':
        color = 'yellow';
        break;
      default:
        color = 'gray';
    }

    const isActive = relevance === value;

    relevanceButtons.push(
      <Button key={idx} outline color={color} onClick={() => setRelevance(value)} active={isActive}
              className={classNames(`px-5 rounded`, {"py-1": isMobile}, {"active": isActive})}>
        {name}
      </Button>
    )
  });

  return (
    <div className={classNames("d-flex gap-2")}>
      {relevanceButtons}
    </div>
  );
};
