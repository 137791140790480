import {useLoginContext} from "../../../util/context/LoginContext";
import {useHistory, useLocation} from "react-router-dom";
import SessionService from "../../../api/SessionService";
import {useEffect} from "react";
import useRegisteredUser from "../../../api/hooks/useRegisteredUser";
import Session from "../../../api/SessionService";

export const AWSLogin = () => {
  const user = useRegisteredUser(null);
  const location = useLocation();
  const history = useHistory();
  const urlSearchParams = new URLSearchParams(location.search);
  const awsStatus = urlSearchParams.get('aws_status');
  const awsUuid = urlSearchParams.get('aws_subscription');
  const {setAwsUuid, setAwsStatus} = useLoginContext();

  useEffect(() => {
    (async () => {
      const sessionValidation = await SessionService.isSessionActive();
      if (sessionValidation.status < 400) {
        if(user?.isCustomerManager){
          await Session.logout();
          history.push("/account/login");
        }else{
          history.push({pathname: '/aws-confirmation', state: {awsStatus, awsUuid, awsSubscriptionSuccess: false}});
          return null;
        }
      } else if (awsStatus === 'successful' && awsUuid) {
        setAwsUuid(awsUuid);
        history.push('account/login');
        return null;
      } else if (awsStatus === 'error') {
        setAwsStatus(awsStatus);
        setAwsUuid(awsUuid);
        history.push('account/login');
        return null;
      }
    })();
  }, [])

  return null;
}
