import {Card, CardBody, Col, Container} from "reactstrap";
import LogoWhite from "../../../assets/img/brand/logo-lumu-white.png";
import classNames from "classnames";
import {Trans} from "react-i18next";
import useMedia from "../../../util/useMedia";
import {RegisterInformationAWS} from "./RegisterInformationAWS";

export const LoginLayoutAWS = ({children, className=''}) => {
  const isLaptop = useMedia("(max-width: 1024px)");
  const isMobile = useMedia("(max-width: 768px)");

  return (
    <div>
      <div className="app">
        <Container fluid className="app-body m-0">
          {!isMobile &&
            <Col md={6} className="bg-lighter d-flex align-items-center justify-content-center flex-column">
              <RegisterInformationAWS/>
            </Col>
          }
          <Col md={12} sm={6} lg={6} className="bg d-flex align-items-center justify-content-center flex-column">
            <div className={'text-center pb-4'}>
              <img src={LogoWhite} width={'150px'} alt="logo"/>
            </div>
            <div className={classNames({"w-520px": !isLaptop, "w-380px": isMobile})}>
              <Card className={`rounded ${className}`}>
                <CardBody>
                  {children}
                </CardBody>
              </Card>
              <div className={classNames({"text-center": isMobile})}>
                <small className="text-white">
                  <Trans
                    i18nKey={'By creating your account you agree to the Lumu Terms of Service and Privacy Policy'}>
                    By creating your account you agree to the <a
                    className="text-primary pointer text-decoration-underline"
                    href={'https://lumu.io/user-terms-of-service'}
                    target='_blank' rel='noopener noreferrer'>
                    Lumu Terms of Service
                  </a> and <a className="text-primary pointer text-decoration-underline"
                              href={'https://lumu.io/privacy-policy/'}
                              target='_blank'
                              rel='noopener noreferrer'>
                    Privacy Policy
                  </a>
                  </Trans>
                </small>
              </div>
            </div>

          </Col>
        </Container>
      </div>
    </div>
  )
}
