import React from 'react';
import {Alert, Badge, Button, Card, CardBody, Col, Container, Label, Row} from "reactstrap";
import {LogoHeader} from "./LogoHeader";
import {Trans, useTranslation} from "react-i18next";
import classNames from "classnames";
import {CardAdvanceMode} from "./CardAdvanceMode";
import {HelpCardAdvance} from "./HelpCard";
import {openDocsByIdUrl} from "../../util/documentation";
import SessionService from "../../api/SessionService";
import {Redirect} from "react-router-dom";

const CollectorType = ({history, location}) => {
  const {t} = useTranslation();
  const firewallDescription = 'When you have a firewall and can modify the firewall configuration to send logs to our virtual machine collector';
  const proxyLogDescription = 'When you control the browsing through a proxy, this allows you to have visibility';
  const dnsQueries = 'When you are not using the virtual appliance’s built-in DNS resolver, but still want to use the Virtual Appliance for collecting DNS metadata';
  const dnsPackets = 'When you have a DNS Server and want to collect DNS logs using Packetbeat';
  const dsn = 'With this option, Lumu\'s Virtual Appliance acts as your primary DNS resolver while collecting metadata in real time';
  const netflowDescription = 'When your network devices can generate and export network flow information in Netflow or IPFIX format';
  const badgesVA = ['Proxy Log', 'Firewall Log', 'DNS', 'Netflow'];

  const isTutorial = location.pathname.startsWith('/advance-tutorial');
  const path = isTutorial ? '/advance-tutorial' : '/start';
  const size = isTutorial ? 10 : 8;

  const currentUser = SessionService.getRegisteredUser();

  if (!currentUser) {
    return <Redirect to={'/dashboard'}/>
  }

  const onClick = () => {
    history.goBack();
  }

  const ChangeButton = () => (
    <Button color={'light'} className={"rounded btn-block px-5 text-muted"} onClick={onClick}>
      Change
    </Button>
  )

  return (
    <div>
      {!isTutorial && <LogoHeader/>}
      <div className="tutorial-height bg-gray-f2">
        <Container fluid className="pt-5 d-flex flex-column tutorial-height justify-content-between">
          <Row className="mt-4 justify-content-center align-items-center">
            <Col md={size}>
              <h2 className="font-weight-bold fnt-darkgray">{t('Collector Type')}</h2>
              <CardAdvanceMode title='Use Lumu to collect network metadata logs using a Virtual Appliance'
                               badges={badgesVA} time='60 to 90 min avg' button={<ChangeButton/>}>
                <Trans i18nKey={'advance_appliance_explain'}>
                  A <a className="text-primary pointer text-decoration-underline"
                       onClick={() => openDocsByIdUrl('420518000000806089', 'https://docs.lumu.io/portal/en/kb/articles/lumu-virtual-appliance')}>
                  virtual appliance</a> is a virtual machine that Lumu provides. This is the most complete solution
                  because it allows you to collect proxy, firewall, and DNS metadata. Use this option if you have
                  sufficient permissions on your infrastructure to deploy our virtual machine.
                </Trans>
              </CardAdvanceMode>
              <h2 className="mt-4 font-weight-bold">{t('Choose metadata Type')}</h2>
              <Alert color="warning">
                <i className={"fa fa-info-circle mr-2"}/>
                {t('You may need to create a new collector if you decide to change the metadata types later')}
              </Alert>
              <CardCollectorType title='Firewall logs' mins='60 min avg' description={firewallDescription}
                                 path={`${path}/appliance-firewall`} history={history}/>
              <CardCollectorType title='Proxy logs' mins='60 min avg' description={proxyLogDescription}
                                 history={history}
                                 path={`${path}/appliance-proxy`}/>
              <CardCollectorType title='DNS Queries' mins='60 min avg' description={dnsQueries} history={history}
                                 path={`${path}/appliance-dns`} category={'dns-queries'}/>
              <CardCollectorType title='DNS Packets' mins='60 min avg' description={dnsPackets} history={history}
                                 path={`${path}/appliance-dns`} category={'dns-packets'}/>
              <CardCollectorType title='DNS Resolver' mins='60 min avg' description={dsn} history={history}
                                 path={`${path}/appliance-dns`} category={''}/>
              <CardCollectorType title='Netflows' mins='60 min avg' description={netflowDescription} isNetflow={true}/>
              <hr/>
              <Label className="font-weight-bold">
                {t('Learn how to use')}
              </Label>
              <Row className='ml-0'>
                <HelpCardAdvance icon={'fa-book'} title={'Learn in details'}
                                 subtitle={'Documentation'} id={'420518000000806089'}
                                 url={"https://docs.lumu.io/portal/en/kb/articles/lumu-virtual-appliance"}
                                 className='mr-2'/>
              </Row>
            </Col>
          </Row>
        </Container>
      </div>
    </div>
  )
}

const CardCollectorType = ({title, mins, description, isNetflow = false, path, history, category = null}) => {
  const {t} = useTranslation();

  const onClick = () => {
    if (title !== 'Netflows') {
      history.push({
        pathname: path,
        state: {
          connect: {label: title, value: category}
        }
      })
    }
  }

  return (
    <Card onClick={onClick} className={classNames({"bg-gray-100": isNetflow, "pointer": !isNetflow})}>
      <CardBody className="d-flex justify-content-between align-items-center">
        <div>
          <div className="fnt-18 font-weight-bold">
            {t(title)}
            {isNetflow && <Badge className="badge-dark ml-1">Paid</Badge>}
          </div>
          <div className='d-flex align-items-center'>
            <div>
              <i className="fa fa-clock-o mr-1"/>
              <small>{mins}</small>
            </div>
          </div>
          <p className="fnt-14 mt-3 mb-0">
            {t(description)}
          </p>
        </div>
        <div>
          <a>
            <h4 className="icon-arrow-right font-weight-bold m-0"/>
          </a>
        </div>
      </CardBody>
    </Card>
  )
}

export default CollectorType;
