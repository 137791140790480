import {barDataThreatItem, chartDataPerPeriod, totalThreatsByCategory} from "./charts";
import {formatNumber, formatNumberMBK} from "./formatter";
import {withFormat} from "./datetime";
import {DateTime} from "luxon";

export default function SecActivityCountsWrapper(activityResponse = [], interval) {
  let totalCategory = chartDataPerPeriod(activityResponse, interval);
  let startDate = DateTime.fromISO(interval.startDate);
  let endDate = DateTime.fromISO(interval.endDate);
  const duration = endDate.diff(startDate, 'days').as('days');
  const period = duration > 3 ? 'day' : 'hour';

  return {
    totals: totalThreatsByCategory(activityResponse),
    options: optionsData(totalCategory, period),
    datasets: [
      barDataThreatItem(totalCategory, 'C2C', 'C&C', '#F9522A'),
      barDataThreatItem(totalCategory, 'Malware', 'Malware', '#FDAF24'),
      barDataThreatItem(totalCategory, 'Phishing', 'Phishing', '#4BA3A5'),
      barDataThreatItem(totalCategory, 'Network Scan', 'Network Scan', '#2FAB8E'),
      barDataThreatItem(totalCategory, 'Other', 'Other', '#4A4B4C'),
      lineDataAll(totalCategory)
    ]
  }
}

const optionsData = (totalCategory, period) => {
  const dates = Object.keys(totalCategory);
  const hasData = dates.length > 0;
  const {0: minDate, length: l, [l - 1]: maxDate} = dates;

  return {
    legend: {display: false},
    tooltips: {
      enabled: true,
      titleFontColor: 'black',
      titleFontStyle: 'normal',
      titleMarginBottom: 8,
      titleSpacing: 4,
      bodyFontColor: 'black',
      bodySpacing: 8,
      backgroundColor: 'rgba(255, 255, 255, 1)',
      caretSize: 0,
      cornerRadius: 0,
      mode: 'label',
      position: 'nearest',
      borderColor: 'rgba(165,165,165,0.3)',
      borderWidth: 2,
      callbacks: {
        title: function (tooltipItems) {
          let dateTitle = DateTime.fromISO(Object.keys(totalCategory)[tooltipItems[0].index]);
          let formattedDate;
          if (period === 'day') {
            formattedDate = withFormat(dateTitle, `LLL/dd/yyyy`);
          } else {
            formattedDate = withFormat(dateTitle, `LLL/dd/yyyy, H'h'`);
          }

          return formattedDate;
        },
        label: function (tooltipItem) {
          if (tooltipItem.yLabel || tooltipItem.datasetIndex === 5) {
            return formatNumber(tooltipItem.yLabel);
          }
        }
      }
    },
    scales: {
      xAxes: [
        {
          stacked: true,
          display: true,
          gridLines: {
            display: false,
            offsetGridLines: true,
            offset: false
          },
          ticks: {
            autoSkip: true,
            maxRotation: 0,
            minRotation: 0,
            autoSkipPadding: 50,
            suggestedMax: maxDate
          },
          labels: Object.keys(totalCategory), // x axis labels for the dates
          type: 'time',
          distribution: 'series',
          time: {
            displayFormats: {
              year: 'MM/DD/YYYY',
              month: 'MM/DD/YYYY',
              week: 'MM/DD/YYYY',
              day: 'MM/DD/YYYY',
              hour: period === 'day' ? 'MM/DD/yyyy' : 'MM/DD/yyyy-H[h]',
              minute: 'MM/DD/yyyy-H[h]'
            }
          }
        }
      ],
      yAxes: [
        {
          stacked: true,
          type: 'linear',
          display: hasData,
          position: 'left',
          id: 'y-axis-1', //threats y axis
          gridLines: {
            display: true
          },
          labels: {
            show: true
          },
          scaleLabel: {
            display: true,
            labelString: 'Threats'
          },
          ticks: {
            precision: 0,
            beginAtZero: true,
            maxTicksLimit: 5,
            callback: function (label, index, labels) {
              return formatNumberMBK(label);
            }
          }
        },
        {
          type: 'linear',
          display: hasData,
          position: 'right',
          id: 'y-axis-2', //All Traffic y axis
          gridLines: {
            display: false //drawOnChartArea: false
          },
          labels: {
            show: true
          },
          scaleLabel: {
            display: true,
            labelString: 'All Traffic'
          },
          ticks: {
            precision: 0,
            beginAtZero: true,
            maxTicksLimit: 5,
            callback: function (label, index, labels) {
              return formatNumberMBK(label);
            }
          }
        }
      ]
    },
    responsive: true, stacked: false,
    maintainAspectRatio: false
  }
};

const lineDataAll = (totalCategory) => {
  return {
    type: 'line',
    label: 'All Traffic',
    data: Object.keys(totalCategory).map(time => totalCategory[time]['All']),
    fill: true,
    lineTension: 0,
    borderColor: '#CCCCCC',
    backgroundColor: 'rgba(204, 204, 204, 0.5)', //background color with 0.5 opacity
    pointBorderColor: '#CCCCCC',
    pointBackgroundColor: '#CCCCCC',
    pointHoverBackgroundColor: '#CCCCCC',
    pointHoverBorderColor: '#CCCCCC',
    yAxisID: 'y-axis-2'
  }
};
