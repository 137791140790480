import LogoWhite from "../../assets/img/brand/logo.png";
import {Card} from "reactstrap";

export const RegisterMessagesLayout = ({children, className=""}) => {
  return (
    <div className="p-3">
      <div id="success-card"  className="d-flex align-items-center justify-content-center flex-column">
        <div className={`text-center p-5`}>
          <img src={LogoWhite} className="my-3" width={'150px'} alt="logo"/>
        </div>
        <Card className={`rounded p-5 ${className}`}>
          {children}
        </Card>
      </div>
    </div>
  )
}
