import Auth, {User} from "./AuthAPI";

class SessionStorage {
  __storage
  __session

  __USER_KEY = "lumu-user"

  constructor() {
    this.__storage = localStorage
    this.__session = sessionStorage
  }

  get user() {
    const value = this.__storage.getItem(this.__USER_KEY)
    if (value)
      return User.fromObj(JSON.parse(value))
    else
      return null
  }

  set user(u) {
    this.__storage.setItem(this.__USER_KEY, JSON.stringify(u))
  }

  set msp_user(u) {
    this.__session.setItem(this.__USER_KEY, JSON.stringify(u))
  }

  get msp_user() {
    const value = this.__session.getItem(this.__USER_KEY)
    if (value)
      return User.fromObj(JSON.parse(value))
    else
      return null
  }

  clear() {
    this.__session.removeItem(this.__USER_KEY)
    this.__storage.removeItem(this.__USER_KEY)
  }
}

class SessionService {
  __storage = new SessionStorage();
  __userChangeListeners = new Set();
  __onStorageEvent = e => {
    if (e.key === this.__storage.__USER_KEY) {
      const newValue = e.newValue ? User.fromObj(JSON.parse(e.newValue)) : null;
      this.__userChangeListeners.forEach(f => f(newValue))
    }
  };

  constructor() {
    window.addEventListener("storage", this.__onStorageEvent);
  }

  signIn(email, password, otp) {
    return Auth.signIn(email, password, otp)
      .then((res) => {
        if (res.status === 202) {
          return {...res, is2FA: true}
        }
        return Auth.__parseUser(res)
      })
      .then((user) => {
        this.__storage.user = user
        return user;
      })
      .catch((r) => {
        this.__storage.clear()
        return Promise.reject(r.response)
      })
  }

  signInWithToken(token, supervisedCompanyId) {
    return Auth.sessionFromToken(token, supervisedCompanyId)
      .then((user) => {
        if (supervisedCompanyId) {
          this.__storage.msp_user = user;
        } else {
          this.__storage.user = user
        }
        return true;
      })
      .catch((r) => {
        return Promise.resolve(false);
      })
  }

  isSessionActive() {
    const user = this.__storage.msp_user ? this.__storage.msp_user : this.__storage.user
    if (!!user) {
      return Auth.validate(user)
        .then((u) => {
          if (u.token)
            this.__storage.user = u
          return u
        })
        .catch(({response}) => {
          this.__storage.clear()
          return Promise.resolve(response);
        })
    }
    return Promise.resolve({})
  }

  logout() {
    const user = this.__storage.user || {};
    const mspUser = this.__storage.msp_user || {};

    if (!!mspUser) {
      this.__storage.clear()
      return Auth.signOut(mspUser)
    }

    if (!!user) {
      this.__storage.clear()
      return Auth.signOut(user)
    }

    return Promise.resolve(true)
  }

  isMspUser() {
    return !!this.__storage.msp_user;
  }

  registerUser(username, company, email, password, geolocation, awsUuid) {
    return Auth.register(username, company, email, password, geolocation, awsUuid)
      .then(u => {
        this.__storage.user = u
        return u
      })
      .catch(e => {
        let error = typeof e.response.data === 'object' ? e.response.data.errors : {base: ['Something went wrong! Please try again later']};
        return Promise.reject({error, statusCode: e.response.status});
      })
  }

  resendEmail = (email) => Auth.resendEmail(email);

  recoverPassword = (email) => Auth.requestResetPassword(email);

  resetPassword = (password, passwordConfirmation, resetToken) => Auth.resetPassword(password, passwordConfirmation, resetToken);

  getRegisteredUser() {
    return this.__storage.msp_user ? this.__storage.msp_user : this.__storage.user;
  }

  setRegisteredUser(user, isMsp = false) {
    if (isMsp) {
      this.__storage.msp_user = user;
    } else {
      this.__storage.user = user;
    }
    this.__userChangeListeners.forEach(f => f(user));
  }

  addUserChangeListener(listener) {
    this.__userChangeListeners.add(listener);
  }

  removeUserChangeListener(listener) {
    this.__userChangeListeners.delete(listener);
  }

  clearUser() {
    this.__storage.clear();
  }

  impersonateCompany(company, listCompanies) {
    const registerUser = this.getRegisteredUser();
    const supervisedCustomer = listCompanies.find(v => v.id === company.id);
    if (supervisedCustomer) {
      const user = User.fromObj({...registerUser, impersonated_company: supervisedCustomer});
      this.setRegisteredUser(user);
    }
  }

  updateApiCollectorKey(apiKey) {
    let company = {...this.getRegisteredUser().getCompany(), custom_collector_api_key: apiKey};
    const user = User.fromObj({...this.getRegisteredUser(), company});
    this.setRegisteredUser(user);
  }

  hideTutorial() {
    const user = User.fromObj({...this.getRegisteredUser(), show_tutorial: false});
    this.setRegisteredUser(user);
  }

  update2FA(isEnable) {
    const user = User.fromObj({...this.getRegisteredUser(), otp_required_for_login: isEnable});
    this.setRegisteredUser(user);
  }

  loggingOut() {
    const user = User.fromObj({...this.getRegisteredUser(), logging_out: true});
    this.setRegisteredUser(user);
  }

  updateTimezone(timezone) {
    const user = User.fromObj({...this.getRegisteredUser(), timezone});
    this.setRegisteredUser(user);
  }

  updateAutopilot(value) {
    let company = this.getRegisteredUser().getCompany();
    const user = User.fromObj({
      ...this.getRegisteredUser(), company: {
        ...company,
        settings: {
          ...company.settings,
          autopilot_status: value
        }
      }
    });
    this.setRegisteredUser(user);
  }

}

export default new SessionService()
