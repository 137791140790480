// rename this to gatewaysList
import {useEffect, useReducer} from "react";
import axios from "axios";
import Session from "../SessionService";
import useRegisteredUser from "./useRegisteredUser";

const reducer = (state, action) => {
  switch (action.type) {
    case 'INIT':
      return {
        ...state,
        isLoading: true,
        __forceRefresh: false,
      }

    case 'SUCCESS':
      return {
        ...state,
        isLoading: false,
        fulfilled: true,
        pagination: action.payload[0],
        items: action.payload[1],
        __forceRefresh: false
      }

    case 'FAILURE':
      return {
        ...state,
        isLoading: false,
        isError: true
      }

    case 'REFRESH':
      return {
        ...state,
        isLoading: true,
        isError: false,
        fulfilled: false,
        items: null,
        __forceRefresh: true
      }
    // no default
  }
}

export default function useRecordList(endPoint, page, maxItems) {
  const user = useRegisteredUser();
  const [state, dispatch] = useReducer(reducer, {
    isLoading: true,
    isError: false,
    fulfilled: false,
    pagination: null,
    items: null,
    __forceRefresh: true
  })

  useEffect(() => {
    if (state.__forceRefresh) {
      (async () => {
        dispatch({type: 'INIT'})

        try {
          const result = await axios.get('/api/v1/' + endPoint,
            {
              headers: user.authenticationHeaders,
              params: {items: maxItems, page}
            }
          )
          // .then(x => new Promise(resolve => setTimeout(() => resolve(x), 1000)))

          dispatch({type: 'SUCCESS', payload: result.data})
        } catch (e) {
          dispatch({type: 'FAILURE'})
        }
      })()
    }
  }, [user.getCompany().uuid, page, maxItems, state.__forceRefresh]);

  return [{...state}, () => dispatch({type: 'REFRESH'})];
}
