import {useTranslation} from "react-i18next";
import registerImg from "../../../assets/img/features/register-view.svg";
import {Col, Row} from "reactstrap";
import socLogo from "../../../assets/img/features/SOC_CPA_Blue.svg";

export const RegisterInformationAWS = ({}) => {
  const {t} = useTranslation();

  return (
    <div className={"max-w-540px px-2"}>
      <h5 className="fnt-16 text-dark-light font-weight-medium text-uppercase mb-2">{t('AWS Marketplace')}</h5>
      <h3 className="mb-4">{t('Activate your Lumu subscription')}</h3>
      <div className={'text-center mb-4'}>
        <img src={registerImg} alt="devices-image"/>
      </div>
      <div className="pb-5">
        <div className="pb-3">
          <span className="fnt-16 font-weight-semi-bold fnt-montserrat">{t('Get started now')}</span>
          <br/>
        </div>
        <div className="pb-3 text-dark-light">
          <i className="fa fa-check"/>
          <span>{t('Discover your compromise level in minutes')}</span>
          <br/></div>
        <div className="pb-3 text-dark-light">
          <i className="fa fa-check"/>
          <span>{t('Implement easily with flexible deployment options')}</span>
          <br/></div>
        <div className="pb-3 text-dark-light">
          <i className="fa fa-check"/>
          <span>{t('Get network-level compromise visibility')}</span>
          <br/></div>
        <div className="text-dark-light">
          <i className="fa fa-check"/>
          <span>{t('Start your continuous compromise assessment journey')}</span>
        </div>
      </div>
      <div>
        <Row className="w-340px bg-white rounded">
          <Col className="p-0" md={"auto"}>
            <img src={socLogo} alt="soc logo" className="p-4"/>
            <div className="soc-vertical-divider"/>
          </Col>
          <Col className="align-self-center p-0">
            <div className="px-4">
              <span className="text-uppercase fnt-14 font-weight-normal fnt-montserrat">
                {t('Lumu is soc2 type ii compliant')}
              </span>
              <br/>
              <a className="text-primary pointer text-decoration-underline pr-3 fnt-14 font-weight-normal fnt-roboto"
                 href={'https://lumu.io/soc2'} target="_blank" rel="noopener noreferrer">
                {t('Learn more')}
              </a>
            </div>
          </Col>
        </Row>
      </div>
    </div>
  )
}
