import {DateTime} from "luxon";

export function fromZonedISODateTime(isoDateTimeString, timezone) {
  const d = DateTime.fromISO(isoDateTimeString, {zone: timezone})
  return d.toLocaleString({...DateTime.DATETIME_MED_WITH_SECONDS, ...DateTime.TIME_24_WITH_SECONDS})
}

export function beginningOfDate(day, start = 'day') {
  return DateTime.fromISO(day).startOf(start).toISO({includeOffset: false})
}

export function endOfDate(day) {
  return DateTime.fromISO(day).endOf('day').toISO({includeOffset: false})
}

export function today() {
  return DateTime.local().toISO({includeOffset: false})
}

export const nowUTC = () => {
  return DateTime.utc();
}

export const nowUTCISO = () => {
  return DateTime.utc().toISO();
}

export const now = () => {
  return DateTime.local();
}

export const currentTimestamp = () => {
  return DateTime.now().toMillis()
}

export function todayPlusMinutes(minutes, timezone) {
  return DateTime.local().plus({minutes: minutes}).setZone(timezone).toISO({includeOffset: false})
}

export function nowUTCPlusDays(days) {
  return nowUTC().plus({days: days}).toISO()
}

export function datetimePlusHours(datetime, hours, mins = 0) {
  return datetime.plus({hours: hours, minutes: mins}).toISO()
}

export function nowUTCPlusMonths(months) {
  return nowUTC().plus({months: months}).toISO()
}

export function todayPlus(plus, unit, timezone) {
  return DateTime.local().plus({[unit]: plus}).setZone(timezone);
}

export function toUserTimezone(isoDateTimeString, timezone) {
  return DateTime.fromISO(isoDateTimeString, {zone: timezone}).toISO({includeOffset: false})
}

export function toDatetime(isoDateTimeString, timezone) {
  return DateTime.fromISO(isoDateTimeString, {zone: timezone});
}

export const toShortUTC = (datetime) => {
  const isoDatetime = datetime.toUTC().toISO();
  return withFormat(isoDatetime, 'yyyy-MM-dd');
}

export const withFormat = (strIsoDate, format) => {
  return DateTime.fromISO(strIsoDate).toFormat(format);
}

export const withFormatTimezone = (strIsoDate, format, timezone) => {
  return DateTime.fromISO(strIsoDate, {zone: timezone}).toFormat(format);
}

export const isSameDay = (aStrDate, bStrDate) => {
  const aDate = DateTime.fromISO(aStrDate).startOf('day');
  const bDate = DateTime.fromISO(bStrDate).startOf('day');
  const duration = bDate.diff(aDate, 'day').as('day');
  return duration === 0;
}

export const fillDateGaps = (strInitDate, strEndDate, activity, type, hasDateFilter) => {
  let firstEvent = activity.length ? DateTime.fromISO(activity[0].datetime) : "";
  let initDate = DateTime.fromISO(strInitDate);
  let endDate;
  if (strEndDate) {
    endDate = DateTime.fromISO(strEndDate);
  } else {
    endDate = DateTime.local().endOf('day');
  }

  if (!hasDateFilter && firstEvent !== "" && firstEvent > initDate) {
    initDate = firstEvent;
  }

  let truncTo = type === "HOURS" ? 'minute' : 'day';
  let timeDiff = Math.floor(endDate.startOf(truncTo).diff(initDate.startOf(truncTo), type).as(type));
  let dateFilled = [];

  if (type === 'HOURS') {
    dateFilled = [{
      count: 0, datetime: initDate.startOf(truncTo).toISO({includeOffset: false, suppressMilliseconds: true})
    }];

    for (let i = 0; i < timeDiff; i++) {
      initDate = initDate.startOf(truncTo).plus({hours: 1});
      dateFilled.push({datetime: initDate.toISO({includeOffset: false, suppressMilliseconds: true}), count: 0});
    }
  } else {
    dateFilled = [{count: 0, datetime: initDate.startOf(truncTo).toISODate()}];

    for (let i = 0; i < timeDiff; i++) {
      initDate = initDate.startOf(truncTo).plus({days: 1});
      dateFilled.push({datetime: initDate.toISODate(), count: 0});
    }
  }

  activity.map(a => {
    let truncDate = a.datetime;
    if (type !== "HOURS") {
      let date = DateTime.fromISO(a.datetime);
      truncDate = date.toISODate();
    }
    let idx = dateFilled.findIndex(d => d.datetime === truncDate);
    if (idx > -1) {
      dateFilled[idx] = {...a, datetime: truncDate};
    }
  })

  return dateFilled
}

export const periodFromNow = (strDate) => {
  let timelapse = DateTime.fromISO(strDate, {locale: "en"}).toRelativeCalendar();

  if (timelapse === 'today') {
    if (DateTime.fromISO(strDate, {locale: "en"}).toRelative({unit: 'hours'}) === '0 hours ago') {
      if (DateTime.fromISO(strDate, {locale: "en"}).toRelativeCalendar({unit: 'minutes'}) === 'in 0 minutes') {
        return 'just now'
      }
      return DateTime.fromISO(strDate, {locale: "en"}).toRelativeCalendar({unit: 'minutes'});
    } else {
      return DateTime.fromISO(strDate, {locale: "en"}).toRelativeCalendar({unit: 'hours'});
    }
  } else if (timelapse === 'last year') {
    timelapse = DateTime.fromISO(strDate, {locale: "en"}).toRelativeCalendar({unit: 'months'});
    if (timelapse === 'last month') {
      timelapse = '1 month ago';
    } else if (timelapse === '12 months ago') {
      timelapse = '1 year ago';
    }
    return timelapse;
  } else {
    return timelapse;
  }
}

export const diffFromNow = (strDate) => {
  const now = nowUTC();
  if (DateTime.fromISO(strDate).isValid) {
    const dateISO = DateTime.fromISO(strDate);
    return dateISO.diff(now, ["days", "hours", "minutes"]).toObject();
  } else {
    return {days: 0, hours: 0, minutes: 0};
  }
}

export const getTimezoneOffset = (timezone) => {
  const now = nowUTC().setZone(timezone);
  return now.toFormat('Z')
}

export const getDiff = (date1, date2) => {
  if (DateTime.fromISO(date1).isValid && DateTime.fromISO(date2).isValid) {
    const date1ISO = DateTime.fromISO(date1);
    const date2ISO = DateTime.fromISO(date2);
    const object = date2ISO.diff(date1ISO, ["years", "months", "days", "hours", "minutes"]).toObject();
    if (object.years > 0) return {label: 'year_incident', value: object.years};
    if (object.months > 0) return {label: 'month_incident', value: object.months};
    if (object.days > 0) return {label: 'day_incident', value: object.days};
    if (object.hours > 0) return {label: 'hour_incident', value: object.hours};
    return {label: 'minute_incident', value: Math.round(object.minutes)};
  } else {
    return '---';
  }
}

export const initOf = (unit) => {
  return nowUTC().startOf(unit);
}

export const initOfWithTimezone = (datetime, unit, timezone) => {
  const userDate = datetime.setZone(timezone);
  return userDate.startOf(unit);
}

export const getTodayDates = (timezone) => {
  const nowUTC = nowUTCISO();
  const dateTimezone = DateTime.fromISO(toUserTimezone(nowUTC, timezone)).startOf('day');
  const isoDate = dateTimezone.toISO({includeOffset: false});
  const utc = DateTime.fromISO(isoDate, {zone: 'utc'});
  const offsetTimezone = getTimezoneOffset(timezone);
  const [hours, mins = 0] = offsetTimezone.split(':');
  const datePlus = datetimePlusHours(utc, -hours, -mins);
  return [datePlus, nowUTC];
}

export const getCurrentWeekRange = () => {
  const currentWeekLastDay = nowUTC();
  const currentWeekLastDayISO = nowUTCISO();
  const currentWeekFirstDay = initOf('week');
  const diff = getDateDiff(currentWeekLastDay, currentWeekFirstDay, ['days']);
  return [Math.floor(diff.days), currentWeekLastDayISO];
}

export const getCurrentWeekRangeDates = (timezone) => {
  const currentWeekLastDayISO = nowUTCISO();
  const currentWeekFirstDay = initOf('week');
  const offsetTimezone = getTimezoneOffset(timezone);
  const [hours, mins = 0] = offsetTimezone.split(':');
  const currentWeekFirstDayUTC = datetimePlusHours(currentWeekFirstDay, -hours, -mins);
  return [currentWeekFirstDayUTC, currentWeekLastDayISO];
}

export const getLastWeekRange = (timezone) => {
  const firstDay = nowUTC().plus({days: -7}).startOf('week');
  const lastDay = firstDay.endOf('week');
  const offsetTimezone = getTimezoneOffset(timezone);
  const [hours, mins = 0] = offsetTimezone.split(':');
  const dateUTC = datetimePlusHours(lastDay, -hours, -mins);
  const diff = getDateDiff(lastDay, firstDay, ['days']);
  return [Math.floor(diff.days), dateUTC];
}

export const getLastWeekRangeDates = (timezone) => {
  const firstDay = nowUTC().plus({days: -7}).startOf('week');
  const lastDay = firstDay.endOf('week');
  const offsetTimezone = getTimezoneOffset(timezone);
  const [hours, mins = 0] = offsetTimezone.split(':');
  const dateInitUTC = datetimePlusHours(firstDay, -hours, -mins);
  const dateFinishUTC = datetimePlusHours(lastDay, -hours, -mins);
  return [dateInitUTC, dateFinishUTC];
}

export const getCurrentMonthRange = (timezone) => {
  const now = nowUTC();
  const userDate = now.setZone(timezone)
  const firstDay = userDate.startOf('month');
  const today = now.setZone(timezone)
  const diff = getDateDiff(today, firstDay, ['days']);
  const todayISO = today.toUTC().toISO();
  return [Math.floor(diff.days), todayISO];
}

export const getCurrentMonthRangeDates = (timezone) => {
  const now = nowUTC();
  const userDate = now.setZone(timezone)
  const endLastMonth = userDate.endOf('day');
  const initLastMonth = userDate.startOf('month');
  const initLastMonthUTC = initLastMonth.toUTC().toISO();
  const endLastMonthUTC = endLastMonth.toUTC().toISO();
  return [initLastMonthUTC, endLastMonthUTC];
}

export function getSelectedMonthRangesDates(ISODate, timezone) {
  const firstDate = DateTime.fromISO(ISODate, {zone: timezone});
  const lastDate = firstDate.endOf('month');
  const firstDateISO = firstDate.toUTC().toISO();
  const lastDateISO = lastDate.toUTC().toISO();
  return [firstDateISO, lastDateISO]
}

export const getLastMonthRange = (timezone) => {
  const now = nowUTC();
  const initCurrentMonth = initOfWithTimezone(now, 'month', timezone);
  const endLastMonth = initCurrentMonth.plus({days: -1}).endOf('day');
  const initLastMonth = endLastMonth.startOf('month');
  const diff = getDateDiff(endLastMonth, initLastMonth, ['days']);
  const lastDayISO = endLastMonth.toUTC().toISO();
  return [Math.floor(diff.days), lastDayISO];
}

export const getLastMonthRangeDates = (timezone) => {
  const now = nowUTC();
  const initCurrentMonth = initOfWithTimezone(now, 'month', timezone);
  const endLastMonth = initCurrentMonth.plus({days: -1}).endOf('day');
  const initLastMonth = endLastMonth.startOf('month');
  const initLastMonthUTC = initLastMonth.toUTC().toISO();
  const endLastMonthUTC = endLastMonth.toUTC().toISO();
  return [initLastMonthUTC, endLastMonthUTC];
}

export const getCurrentQuarterRange = () => {
  const firstDayQuarter = initOf('quarter');
  const today = nowUTC();
  const todayISO = nowUTCISO();
  const diff = getDateDiff(today, firstDayQuarter, ['days']);
  return [Math.floor(diff.days), todayISO];
}

export const getCurrentQuarterRangeDates = (timezone) => {
  const firstDayQuarter = initOf('quarter');
  const todayISO = nowUTCISO();
  const offsetTimezone = getTimezoneOffset(timezone);
  const [hours, mins = 0] = offsetTimezone.split(':');
  const currentFirstDayUTC = datetimePlusHours(firstDayQuarter, -hours, -mins);
  return [currentFirstDayUTC, todayISO];
}

export const getLastQuarterRange = (timezone) => {
  const lastDayQuarter = initOf('quarter').plus({days: -1}).endOf('day');
  const firstDayQuarter = lastDayQuarter.startOf('quarter');
  const offsetTimezone = getTimezoneOffset(timezone);
  const [hours, mins = 0] = offsetTimezone.split(':');
  const dateUTC = datetimePlusHours(lastDayQuarter, -hours, -mins);
  const diff = getDateDiff(lastDayQuarter, firstDayQuarter, ['days']);
  return [Math.floor(diff.days), dateUTC];
}

export const getLastQuarterRangeDates = (timezone) => {
  const lastDayQuarter = initOf('quarter').plus({days: -1}).endOf('day');
  const firstDayQuarter = lastDayQuarter.startOf('quarter');
  const offsetTimezone = getTimezoneOffset(timezone);
  const [hours, mins = 0] = offsetTimezone.split(':');
  const dateInitUTC = datetimePlusHours(firstDayQuarter, -hours, -mins);
  const dateFinishUTC = datetimePlusHours(lastDayQuarter, -hours, -mins);
  return [dateInitUTC, dateFinishUTC];
}

export const getCurrentYearRange = () => {
  const firstDay = initOf('year');
  const today = nowUTC();
  const todayISO = nowUTCISO();
  const diff = getDateDiff(today, firstDay, ['days']);
  return [Math.floor(diff.days), todayISO];
}

export const getCurrentYearRangeDates = (timezone) => {
  const firstDay = initOf('year');
  const todayISO = nowUTCISO();
  const offsetTimezone = getTimezoneOffset(timezone);
  const [hours, mins = 0] = offsetTimezone.split(':');
  const currentFirstDayUTC = datetimePlusHours(firstDay, -hours, -mins);
  return [currentFirstDayUTC, todayISO];
}

export const getLastYearRange = (timezone) => {
  const lastDayYear = initOf('year').plus({days: -1}).endOf('day');
  const firstDayYear = lastDayYear.startOf('year');
  const offsetTimezone = getTimezoneOffset(timezone);
  const [hours, mins = 0] = offsetTimezone.split(':');
  const dateUTC = datetimePlusHours(lastDayYear, -hours, -mins);
  const diff = getDateDiff(lastDayYear, firstDayYear, ['days']);
  return [Math.floor(diff.days), dateUTC];
}

export const getLastYearRangeDates = (timezone) => {
  const lastDayYear = initOf('year').plus({days: -1}).endOf('day');
  const firstDayYear = lastDayYear.startOf('year');
  const offsetTimezone = getTimezoneOffset(timezone);
  const [hours, mins = 0] = offsetTimezone.split(':');
  const dateInitUTC = datetimePlusHours(firstDayYear, -hours, -mins);
  const dateFinishUTC = datetimePlusHours(lastDayYear, -hours, -mins);
  return [dateInitUTC, dateFinishUTC];
}

export const startDateCustom = (date, timezone) => {
  const start = DateTime.fromISO(toUserTimezone(date, timezone)).startOf('day');
  const isoDate = start.toISO({includeOffset: false});
  const utc = DateTime.fromISO(isoDate, {zone: 'utc'});
  const offsetTimezone = getTimezoneOffset(timezone);
  const [hours, mins = 0] = offsetTimezone.split(':');
  return datetimePlusHours(utc, -hours, -mins);
}

export const endDateCustom = (date, timezone) => {
  const end = DateTime.fromISO(toUserTimezone(date, timezone)).endOf('day');
  const isoDate = end.toISO({includeOffset: false});
  const utc = DateTime.fromISO(isoDate, {zone: 'utc'});
  const offsetTimezone = getTimezoneOffset(timezone);
  const [hours, mins = 0] = offsetTimezone.split(':');
  return datetimePlusHours(utc, -hours, -mins);
}

export const getDateDiff = (date1, date2, ranges) => date1.diff(date2, ranges).toObject();

export const fromJSDateToISO = (jsDate) => DateTime.fromJSDate(jsDate).toISO({includeOffset: false});

export const formatMinutes = (mins) => {
  if (0 <= mins && mins < 60) return {value: mins, unit: 'minute'}

  const hours = mins / 60;
  return {value: hours, unit: 'hour'}
}

export const nowDiffMinutes = (isoDateStr) => {
  const today = nowUTC();
  const isoDate = DateTime.fromISO(isoDateStr);
  return today.diff(isoDate, 'minute').as('minute');
}

export const getLastNRangeDates = (days, timezone) => {
  const now = nowUTC();
  const offsetTimezone = getTimezoneOffset(timezone);

  const lastDay = now.endOf('day');
  const firstDay = now.plus({days: -days}).startOf('day');

  const [hours, mins = 0] = offsetTimezone.split(':');
  const lastDayUTC = datetimePlusHours(lastDay, -hours, -mins);
  const firstDayUTC = datetimePlusHours(firstDay, -hours, -mins);

  return [firstDayUTC, lastDayUTC];
}
