import {DateTime} from 'luxon'

export function toggleDataSet(activity, toggleOptions) {
  const datasetToToggle = activity.datasets.find(data => data.label === toggleOptions.dataSetLabel)
  if (datasetToToggle) {
    datasetToToggle.hidden = toggleOptions.hidden

    // toggles 2nd y-axis when All traffic
    if (isAll(toggleOptions.dataSetLabel)) {
      activity.options.scales.yAxes[1].display = !toggleOptions.hidden && datasetToToggle.data.length
    }

    // hides 1st y-axis when no bars displayed
    const areThereBars = isDisplayingBars(activity.datasets)
    activity.options.scales.yAxes[0].display = areThereBars

    // toggles 2nd y-axis gridlines
    activity.options.scales.yAxes[1].gridLines.display = !areThereBars && activity.options.scales.yAxes[1].display
  }
}

const isDisplayingBars = (chartData = []) => {
  const barData = chartData.filter(data => data.type === 'bar')

  // bars are displeyd when not hidden and data > 0
  return barData.some(bar => !bar.hidden && bar.data.some(point => point > 0))
}

const isAll = (label) => {
  return label.toLowerCase().includes('all')
}

const fillGaps = (hourlyDistributions, range) => {
  if (!Boolean(hourlyDistributions.length)) return []

  let zeroesCollectionAcc = []

  // tail and head zero fill
  const firstItemDate = DateTime.fromISO(hourlyDistributions[0].datetime)
  const lastItemDate = DateTime.fromISO(hourlyDistributions[hourlyDistributions.length - 1].datetime)

  const tail = zeroesCollection(range.startDate, firstItemDate)
  const head = zeroesCollection(lastItemDate, range.endDate)

  // intermediate zero fill
  hourlyDistributions.forEach((item, index) => {
    if (index < hourlyDistributions.length - 1) {
      const currItemDate = DateTime.fromISO(item.datetime)
      const nextItemDate = DateTime.fromISO(hourlyDistributions[index + 1].datetime)

      zeroesCollectionAcc = zeroesCollectionAcc.concat(zeroesCollection(currItemDate, nextItemDate))
    }
  })

  hourlyDistributions = hourlyDistributions.concat(zeroesCollectionAcc)
  hourlyDistributions = tail.concat(hourlyDistributions)
  hourlyDistributions = hourlyDistributions.concat(head)

  return sortArrayByDate(hourlyDistributions)
}

const zeroesCollection = (startDate, endDate) => {
  const hourGap = Math.ceil(endDate.diff(startDate, 'hours').as('hours'))
  return hourGap > 1 ? fillerArray(hourGap - 1, startDate) : []
}

const fillerArray = (length, startHour) => {
  const fillerSegment = new Array(Math.ceil(length))

  fillerSegment.fill({
    datetime: '',
    distribution: emptyItem()
  })

  let plusHour = startHour
  return fillerSegment.map(x => {
    plusHour = plusHour.plus({hours: 1})
    return {
      ...x,
      datetime: plusHour.toISO({includeOffset: false, suppressMilliseconds: true})
    }
  })
}

const sortArrayByDate = (unordered) => {
  return unordered.sort((t1, t2) => {
    return new Date(t1.datetime).getTime() - new Date(t2.datetime).getTime()
  })
}


// actual chart data
export const chartDataPerPeriod = (activityResponse, interval) => {
  let startDate = DateTime.fromISO(interval.startDate)
  let endDate = DateTime.fromISO(interval.endDate)

  if (activityResponse.length) {
    activityResponse = sortArrayByDate(activityResponse)

    startDate = interval.isDefault ? DateTime.fromISO(activityResponse[0].datetime) : DateTime.fromISO(interval.startDate)
    endDate = DateTime.fromISO(interval.endDate)
  }

  const duration = endDate.diff(startDate, 'days').as('days')
  const functionKey = (duration > 3 ? 'day' : 'hour')

  const calcFunction = {
    hour: totalByCategoryByHour,
    day: totalByCategoryByDay
  }

  let timeFilled = fillGaps(activityResponse, {startDate, endDate});
  return calcFunction[functionKey](timeFilled)
}

export const totalThreatsByCategory = (activityResponse) => {
  return activityResponse.reduce((res, item) => {
    const sumItem = summarizedItem(item)

    res["All"] += sumItem['All']
    res["Malware"] += sumItem['Malware']
    res["C2C"] += sumItem['C2C']
    res["Other"] += sumItem['Other']
    res["Phishing"] += sumItem['Phishing']
    res["Network Scan"] += sumItem['Network Scan']
    res["TotalThreats"] += sumItem['Incidents']
    return res
  }, emptyItem())
}

export const emptyItem = () => {
  return {
    "All": 0,
    "Malware": 0,
    "C2C": 0,
    "Other": 0,
    "Phishing": 0,
    "Network Scan": 0,
    "TotalThreats": 0
  }
}

export const barDataThreatItem = (totalCategory, totalItem, title, color) => {
  return {
    type: 'bar',
    label: title,
    data: Object.keys(totalCategory).map(time => totalCategory[time][totalItem]),
    backgroundColor: color,
    yAxisID: 'y-axis-1',
    hidden: false
  }
}

const totalByCategoryByDay = (array) => {
  let result = []

  return array.reduce(function (res, item) {
    const itemId = DateTime.fromISO(item.datetime).toISODate()

    if (!res[itemId]) {
      res[itemId] = emptyItem()
      result.push(res[itemId])
    }
    res[itemId] = addItems(res[itemId], summarizedItem(item))
    return res
  }, {})
}

const addItems = (item1, item2) => {
  return {
    'All': item1['All'] + item2['All'],
    "Malware": item1['Malware'] + item2['Malware'],
    "C2C": item1['C2C'] + item2['C2C'],
    "Other": item1['Other'] + item2['Other'],
    "Phishing": item1['Phishing'] + item2['Phishing'],
    "Network Scan": item1['Network Scan'] + item2['Network Scan'],
    "TotalThreats": item1['TotalThreats'] + item2['TotalThreats']
  }
}

const totalByCategoryByHour = (array) => {
  const transformedData = {}
  array.forEach(item => transformedData[item.datetime] = summarizedItem(item))
  return transformedData
}

export const summarizedItem = (item) => {
  return {
    "All": (item.distribution['All'] || 0),
    "Malware": (item.distribution['Malware'] || 0) + (item.distribution['DGA'] || 0),
    "C2C": (item.distribution['C2C'] || 0),
    "Other": (item.distribution['Mining'] || 0) + (item.distribution['Spam'] || 0),
    "Phishing": (item.distribution['Phishing'] || 0),
    "Network Scan": (item.distribution['Network Scan'] || 0),
    "Incidents": (item.distribution['Incidents'] || 0)
  }
}

export function toggleDataSetSpambox(activity, toggleOptions) {
  const datasetToToggle = activity.datasets.find(data => data.label === toggleOptions.dataSetLabel);
  if (datasetToToggle) {
    datasetToToggle.hidden = toggleOptions.hidden;
  }
}


// actual chart data
export const chartDataPerPeriodDays = (activityResponse, interval, slotUnit, hasDateFilter) => {
  let startDate = DateTime.fromISO(interval.startDate)
  let endDate = DateTime.fromISO(interval.endDate)

  if (activityResponse.length) {
    activityResponse = sortArrayByDate(activityResponse)

    startDate = interval.isDefault ? DateTime.fromISO(activityResponse[0].datetime) : DateTime.fromISO(interval.startDate)
    endDate = DateTime.fromISO(interval.endDate)
  }

  let timeFilled = fillDateGaps(startDate, endDate, activityResponse, slotUnit, hasDateFilter);

  return totalByCategoryByDay(timeFilled)
};

export const fillDateGaps = (strInitDate, strEndDate, activity, type, hasDateFilter) => {
  let firstEvent = activity.length ? DateTime.fromISO(activity[0].datetime) : "";
  let initDate = DateTime.fromISO(strInitDate);
  let endDate = DateTime.fromISO(strEndDate);

  if (!hasDateFilter && firstEvent !== "" && firstEvent > initDate) {
    initDate = firstEvent;
  }

  let truncTo = type === "HOURS" ? 'minute' : 'day';
  let timeDiff = Math.floor(endDate.startOf(truncTo).diff(initDate.startOf(truncTo), type).as(type));
  let dateFilled = [];


  dateFilled = [{
    distribution: {
      "Malware": 0,
      "C2C": 0,
      "Other": 0,
      "Phishing": 0,
      "Network Scan": 0,
      "TotalThreats": 0
    }, datetime: initDate.startOf(truncTo).toISODate()
  }];

  for (let i = 0; i < timeDiff; i++) {
    initDate = initDate.startOf(truncTo).plus({days: 1});
    dateFilled.push({
      datetime: initDate.toISODate(), distribution: {
        "Malware": 0,
        "C2C": 0,
        "Other": 0,
        "Phishing": 0,
        "Network Scan": 0,
        "TotalThreats": 0
      }
    });
  }

  activity.map(a => {
    let truncDate = a.datetime;
    let idx = dateFilled.findIndex(d => {
      let test = truncDate.split('T');
      return (d.datetime === test[0])
    });

    if (idx > -1) {
      dateFilled[idx] = {...a, datetime: truncDate};
    }
  })

  return dateFilled
}


// incident chart data
export const incidentChartDataPerPeriod = (activityResponse) => {

  const {slotUnit, slots} = activityResponse;
  let startDate = DateTime.fromISO(slots[0].datetime);
  let endDate = DateTime.fromISO(slots[slots.length - 1].datetime);

  const functionKey = (slotUnit === 'HOURS' ? 'hour' : 'day');

  const calcFunction = {
    hour: totalByCategoryByHourCount,
    day: totalByCategoryByDayCount
  };

  let timeFilled = fillGapsCount(slots, {startDate, endDate});

  return calcFunction[functionKey](timeFilled)
};

const fillGapsCount = (hourlyDistributions, range) => {
  if (!Boolean(hourlyDistributions.length)) return [];

  let zeroesCollectionAcc = [];

  // tail and head zero fill
  const firstItemDate = DateTime.fromISO(hourlyDistributions[0].datetime);
  const lastItemDate = DateTime.fromISO(hourlyDistributions[hourlyDistributions.length - 1].datetime);

  const tail = zeroesCollectionCount(range.startDate, firstItemDate);
  const head = zeroesCollectionCount(lastItemDate, range.endDate);

  // intermediate zero fill
  hourlyDistributions.forEach((item, index) => {
    if (index < hourlyDistributions.length - 1) {
      const currItemDate = DateTime.fromISO(item.datetime);
      const nextItemDate = DateTime.fromISO(hourlyDistributions[index + 1].datetime);

      zeroesCollectionAcc = zeroesCollectionAcc.concat(zeroesCollectionCount(currItemDate, nextItemDate))
    }
  });

  hourlyDistributions = hourlyDistributions.concat(zeroesCollectionAcc);
  hourlyDistributions = tail.concat(hourlyDistributions);
  hourlyDistributions = hourlyDistributions.concat(head);

  return sortArrayByDate(hourlyDistributions)
};

const zeroesCollectionCount = (startDate, endDate) => {
  const hourGap = Math.ceil(endDate.diff(startDate, 'hours').as('hours'));
  return hourGap > 1 ? fillerArrayCount(hourGap - 1, startDate) : []
};

const fillerArrayCount = (length, startHour) => {
  const fillerSegment = new Array(Math.ceil(length));
  fillerSegment.fill({
    datetime: '',
    count: 0
  });

  let plusHour = startHour;
  return fillerSegment.map(x => {
    plusHour = plusHour.plus({hours: 1});
    return {
      ...x,
      datetime: plusHour.toISO({includeOffset: false, suppressMilliseconds: true})
    }
  })
};

const totalByCategoryByHourCount = (array) => {
  const transformedData = {};
  array.forEach(item => transformedData[item.datetime] = {count: item.count});
  return transformedData
};

const totalByCategoryByDayCount = (array) => {
  let result = [];

  return array.reduce(function (res, item) {
    const itemId = DateTime.fromISO(item.datetime).toISODate();

    if (!res[itemId]) {
      res[itemId] = {count: 0};
      result.push(res[itemId])
    }
    let add = item.count ? item.count : 0;
    res[itemId] = {count: res[itemId].count + add};

    return res
  }, {})
};
