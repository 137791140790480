import React, {useEffect, useState} from 'react';
import {Link} from 'react-router-dom';
import {Button, Form, FormGroup} from 'reactstrap';
import {useTranslation} from "react-i18next";
import {EmailInput} from "../../util/Forms";


const LoginFormSSO = () => {
  const {t} = useTranslation();
  const [email, setEmail] = useState({value: '', isValid: false});
  const [isPosting, setIsPosting] = useState(false);

  useEffect(() => {

    return () => {
      setIsPosting(false)
    }
  },[])

  const handleSubmit = () => {
    setIsPosting(true)
  }

  return (
    <div>
      <Form className="" noValidate action={"/api/v1/users/saml/sign_in"} method={"post"} onSubmit={handleSubmit}>
        <h2 className="font-weight-bold">{t('Login with your account information')}</h2>
        <p className={"mb-4"}>
          {t('Log in with the email you normally use to access Lumu. You will be redirected to your chosen SSO identity provider for verification.')}
        </p>
        <FormGroup>
          <EmailInput
            type="text"
            name="email"
            autoComplete="email"
            placeholder="someone@example.com"
            value={email.value}
            onChange={(e, isValid) => setEmail({value: e.target.value.trim(), isValid: isValid})}
            className={"form-control-lg"}
          />
        </FormGroup>

        <Button color="primary" size={"lg"} className="px-4" block disabled={isPosting}>
          {isPosting &&
            <div className='spinner-border spinner-border-sm mr-2' role="status" aria-hidden="true"/>
          }
          {t('Login')}
        </Button>
      </Form>
      <Button color="link" tag={Link} to="/account/login"
              className="text-muted px-0 mb-5">
        {t('Regular sign in')}
      </Button>
      <br/>
      <span className="text-muted px-0">{t('dont_have_an_account_yet')}</span>
      <Link to="/account/sign-up" className="pt-0 pl-2">{t('Register')}</Link>
    </div>
  );
};


export default LoginFormSSO;
