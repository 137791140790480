export const getTierName = (tier) => {
  switch (tier) {
    case 1:
      return {value: 'Trial', description: 'Asset-level visibility for a limited period of time'}
    case 2:
      return {value: 'Insights', description: 'Extended compromise intelligence'}
    case 3:
      return {value: 'Partner', description: 'Together, we can help organizations of all sizes'}
    case 4:
    case 5:
      return {value: 'Defender', description: 'Automated response capabilities'}
    default:
      return {value: 'Free', description: 'Confirmed compromise visibility'}
  }
}
