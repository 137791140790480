export const formatNumber = (value) => {
  const locale = navigator.language;
  const baseLanguages = ["es", "en"];
  let formatter = {};

  if (isNaN(value)) {
    return '---';
  }

  if (!formatter[locale]) {
    let language = locale.split('-')[0];

    if (baseLanguages.includes(language)) {
      formatter[locale] = new Intl.NumberFormat(locale);
    } else {
      formatter[locale] = new Intl.NumberFormat('en');
    }
  }

  return formatter[locale].format(value);
}

export const formatShortMBK = (x) => {
  if (!x) return x;

  if (x < 10000) {
    return formatNumber(x);
  }

  if (x < 1000000) {
    return formatNumber((x / 1000).toFixed(2)) + "K";
  }
  if (x < 10000000) {
    return formatNumber((x / 1000000).toFixed(2)) + "M";
  }

  if (x < 1000000000) {
    return formatNumber(((x / 1000000).toFixed(2))) + "M";
  }

  if (x < 1000000000000) {
    return formatNumber(((x / 1000000000).toFixed(2))) + "B";
  }

  return "1T+";
}

export const formatNumberMBK = (x) => {
  if(isNaN(x)){
    return x
  }

  if (!x) return x;

  if (x <= 999) {
    return formatNumber(x);
  }

  if (x < 1000000) {
    return formatNumber((x / 1000).toFixed(2)) + "K";
  }
  if (x < 10000000) {
    return formatNumber((x / 1000000).toFixed(2)) + "M";
  }

  if (x < 1000000000) {
    return formatNumber(((x / 1000000).toFixed(2))) + "M";
  }

  if (x < 1000000000000) {
    return formatNumber(((x / 1000000000).toFixed(2))) + "B";
  }

  return "1T+";
}

export const formatPercentage = (value) => {
  if (isNaN(value)) {
    return '0%'
  }
  if (value <= 1000) {
    const abs = Math.abs(value);
    return abs % 1 === 0 ? `${formatNumber(abs)}%` : `${abs.toFixed(1)}%`;
  } else {
    return `+${formatNumber(1000)}%`
  }
}

export const formatFromMilliseconds = (ms) => {
  if (ms < 1000) return {value: ms, unit: 'millisecond'}

  const secs = ms / 1000;
  if (secs < 60) return {value: secs, unit: 'second'}

  const mins = secs / 60;
  if (1 < mins && mins < 60) return {value: mins, unit: 'minute'}

  const hours = mins / 60;
  if (1 < hours && hours < 24) return {value: hours, unit: 'hour'}

  const days = hours / 24;
  return {value: days, unit: 'day'}
}

export const getTimeFormatted = (value, t) => {
  const response = formatFromMilliseconds(value);
  const responseTime = response.value % 1 === 0 ? response.value : response.value.toFixed(1);

  if (responseTime > 0) {
    return t(response.unit, {count: Number(responseTime)});
  }

  return '-';
}

export const formatBytes = (bytes, decimals = 2, shortBytesFormat) => {
  if (!+bytes) return '0 Bytes'

  const k = 1024
  const dm = decimals < 0 ? 0 : decimals
  const bytesFormat = shortBytesFormat ? 'B' : 'Bytes';
  const sizes = [bytesFormat, 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

  const i = Math.floor(Math.log(bytes) / Math.log(k))

  return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`
}
