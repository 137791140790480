import {Component, useEffect, useState} from "react";
import SessionService from "../api/SessionService";
import {Redirect, Route, Switch, useHistory, useLocation} from "react-router-dom";
import SetupGuide from "../views/OnBoarding/SetupGuide";
import {SimpleLoading} from "./loading";
import Tutorial from "../views/OnBoarding/Tutorial";
import StartAdvanceMode from "../views/OnBoarding/StartAdvanceMode";
import CollectorType from "../views/OnBoarding/CollectorType";
import {SessionProvider} from "./context/SessionContext";
import {AWSAccountConfirmation} from "../views/Login/aws/MessagesComponent";
import queryString from "query-string";
import Billing from "../views/Login/Billing";

export const UnauthenticatedRoute = (props) => {
  const history = useHistory();
  const location = useLocation();
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const {component: Component, loading: Loading, ...rest} = props
  const [userToken] = useState(queryString.parse(location.search)['auth_token']);

  useEffect(() => {
    if (userToken) {
      SessionService.signInWithToken(userToken)
        .then(_ => {
          history.push('/dashboard');
        })
    }
  }, [])

  useEffect(() => {
    (async () => {
      const sessionValidation = await SessionService.isSessionActive();
      setIsAuthenticated(sessionValidation.status < 400);
      setIsLoading(false);
    })();
  }, [])

  if (isLoading) {
    return <Loading {...rest}/>
  } else {
    const isAWS = props.location.pathname.includes('/aws-login');
    return (
      <Route {...rest} render={(props) => (
        !isAuthenticated || isAWS ?
          <Component {...props}/> :
          <Redirect to='/'/>
      )}/>
    )
  }
}

export const AuthenticatedRoute = (props) => {
  const user = SessionService.getRegisteredUser();
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const {component: Component, loading: Loading, history, ...rest} = props

  useEffect(() => {
    (async () => {
      const sessionValidation = await SessionService.isSessionActive();
      setIsAuthenticated(sessionValidation.status < 400);
      setIsLoading(false);
    })();

    return () => {
      window.ZohoHCAsapReady(function () {
        window.ZohoHCAsap.Action("hideLauncher");
      });
    }
  }, [])

  if (isAuthenticated) {
    window.ZohoHCAsapSettings.ticketsSettings.preFillFields.email.defaultValue = user?.email;
    window.ZohoHCAsapSettings.ticketsSettings.preFillFields.contactId.defaultValue = user?.name;

    window.ZohoHCAsapReady(function () {
      window.ZohoHCAsap.Action("showLauncher");
    });
  }

  const isAdminRoute = (path) => {
    if (user) {
      const ownProfileRegExp = new RegExp("manage.+\/edit\/" + user.uid)
      return !path.match(ownProfileRegExp) && (path.match(/manage.+\/edit\//) || path.match(/\/upgrade-.+/))
    }
  }

  if (isLoading) {
    return <Loading/>
  } else {
    if (!(isAuthenticated && user)) {
      return <Redirect to={{
        pathname: '/account/login',
        state: {referrer: rest.location.pathname, hash: rest.location.hash}
      }}/>
    } else {

      const isAdminR = isAdminRoute(rest.location.pathname);
      const user = SessionService.getRegisteredUser();
      const isAllowedRoute = (isAdminR && user.isAdmin) || !isAdminR;
      const showTutorial = user && user.show_tutorial;

      const redirect = (props) => {
        if (isAllowedRoute) {
          if (showTutorial) {
            return <Redirect to='/start'/>;
          } else {
            return <Component {...props} />;
          }
        } else {
          return <Redirect to={'/dashboard'}/>;
        }
      }

      return (
        <SessionProvider>
          <Switch>
            <Route path="/account-setting/billing" component={Billing} exact/>
            <Route path="/aws-confirmation" component={AWSAccountConfirmation} loading={SimpleLoading} exact/>
            <Route path="/start" component={SetupGuide} loading={SimpleLoading} exact/>
            <Route path="/start/advance-mode/appliances" component={CollectorType} loading={SimpleLoading} exact/>
            <Route path="/start/advance-mode" component={StartAdvanceMode} loading={SimpleLoading} exact/>
            <Route path="/start/*" component={Tutorial} loading={SimpleLoading}/>
            <Route {...rest} render={(props) => redirect(props)}/>
          </Switch>
        </SessionProvider>
      )
    }
  }
}
