import React, {lazy, Suspense} from 'react'
import {Router, Switch} from 'react-router-dom'
import './App.scss'
import Account from "./views/Login/Account";
import {SimpleLoading} from "./util/loading";
import {AuthenticatedRoute, UnauthenticatedRoute} from "./util/Routes";
import ReactGA from "react-analytics-ga4";
import {createBrowserHistory} from 'history';
import useMedia from "./util/useMedia";

const DefaultMainPanel = lazy(() => import('./containers/DefaultLayout/MainPanel'));
const history = createBrowserHistory();

ReactGA.initialize('G-VT8XKXK8W5');
history.listen(location => ReactGA.set({page: location.pathname}));

const App = () => {
  const isMobile = useMedia("(max-width: 576px)");
  if (!isMobile)
    window.ZohoHCAsapSettings.layoutAdjustments.minusHeight = "55px";

  return (
    <Router history={history}>
      <Suspense fallback={<SimpleLoading/>}>
        <Switch>
          <UnauthenticatedRoute path="/account" component={Account} loading={SimpleLoading}/>
          <AuthenticatedRoute path="/" name="Home" component={DefaultMainPanel} loading={SimpleLoading}/>
        </Switch>
      </Suspense>
    </Router>
  )
};

export default App
