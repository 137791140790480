import axios from 'axios'

export class User {
  constructor(uid, email, name, timezone, role, company, jti, token, supervised_customers = null, impersonated_company = null, signInCount, showTutorial, otpRequired, loggingOut, time_zone_name = null) {
    this.uid = uid
    this.email = email
    this.name = name
    this.role = role
    this.timezone = timezone
    this.time_zone_name = time_zone_name
    this.sign_in_count = signInCount
    this.show_tutorial = showTutorial
    this.otp_required_for_login = otpRequired
    this.logging_out = loggingOut
    this.company = company
    this.impersonated_company = impersonated_company
    this.jti = jti
    this.token = token
    supervised_customers && (this.supervised_customers = supervised_customers)

    this.isCustomerManager = this.role === "customer_manager"
    this.isAdmin = this.role === "admin"
  }

  get authenticationHeaders() {
    return {
      "Authorization": `Bearer ${this.token}`
    }
  }

  get authenticationToken() {
    return this.token
  }

  getCompany() {
    return this.impersonated_company ? this.impersonated_company : this.company;
  }

  static fromObj({
                   uid,
                   email,
                   name,
                   timezone,
                   role,
                   company,
                   jti,
                   token,
                   supervised_customers = null,
                   impersonated_company = null,
                   sign_in_count,
                   show_tutorial = false,
                   otp_required_for_login = false,
                   logging_out = false,
                 }) {
    return new User(uid, email, name, timezone, role, company, jti, token, supervised_customers, impersonated_company, sign_in_count, show_tutorial, otp_required_for_login, logging_out, null)
  }
}

export const APIBaseHost = process.env.REACT_APP_HOST || 'https://portal.lumu.io'

class AuthenticationServices {
  constructor() {
    this.source = axios.CancelToken.source();
    this.service = axios.create({
      baseURL: '/api/v1'
    })
  }

  signIn = (email, password, otp) => this.service
    .post('/users/sign_in', {user: {email: email, password: password, otp_attempt: otp}})

  signOut = (user) => this.service
    .delete('/users/sign_out', {
      cancelToken: this.source.token,
      headers: user.authenticationHeaders
    }).then(r => !!r.data.success);

  validate = (user) => this.service
    .get('/validate_token', {
      cancelToken: this.source.token,
      headers: user.authenticationHeaders
    }).then(user);

  refreshSession = (user) => this.service
    .post('/users/sign_in', null, {headers: user.authenticationHeaders})
    .then(this.__parseUser);

  sessionFromToken = (token, supervisedCompanyId) => {
    try {
      const claims = JSON.parse(atob(token.split('.')[1]));
      const userId = claims.userId;

      return this.service
        .get(`/users/${userId}`,
          {
            params: {company_id: supervisedCompanyId},
            headers: {"Authorization": `Bearer ${token}`}
          })
        .then(r => {
          const {id, jti, email, name, role, time_zone, company, supervised_customers = null, sign_in_count} = r.data;
          return new User(id, email, name, time_zone, role, company, jti, token, supervised_customers, null, sign_in_count, sign_in_count === 1);
        });
    } catch (e) {
      return Promise.reject("Failed")
    }
  };

  register = async (username, company, email, password, geolocation, awsUuid) => {
    try {
      const res = await this.service.post('/users', {
        user: {
          "email": email,
          'password': password,
          'company_name': company,
          'name': username,
          'redirect_url': `${APIBaseHost}/account/sign-up/success`,
          'provided_country': geolocation.provided_country.value,
          'ip_country': geolocation.ip_country.value,
          'ip_region': geolocation.ip_region,
          'registration_ip': geolocation.registration_ip,
          'aws_subscription_uuid': awsUuid
        }
      });
      return this.__parseUser(res);
    } catch (e) {
      return Promise.reject(e);
    }
  };

  requestResetPassword = (email) =>
    this.service.post('/users/password',
      {
        user: {
          email,
          'redirect_url': `${APIBaseHost}/account/reset-password`
        }
      }
    ).then(r => r.data)

  resetPassword = (password, passwordConfirmation, accessToken) =>
    this.service.put('/users/password',
      {
        user: {
          "password": password,
          "password_confirmation": passwordConfirmation,
          "reset_password_token": accessToken
        }
      }
    ).then(r => true);

  resendEmail = (email) =>
    this.service.post('/users/confirmation_forwarding',
      {
        email: email,
        'redirect_url': `${APIBaseHost}/account/sign-up/success`
      }
    ).then(r => r.data)

  async __parseUser(r) {
    const {
      id,
      jti,
      email,
      name,
      role,
      time_zone,
      time_zone_name,
      company,
      sign_in_count,
      supervised_customers = null,
      otp_required_for_login = false
    } = r.data;

    let token = r.headers['authorization'];
    if (token && token.startsWith('Bearer')) {
      token = token.slice(7, token.length);
    }

    let showTutorial = false;
    if (role === 'admin' && sign_in_count === 1) {
      showTutorial = true;
    }
    return new User(id, email, name, time_zone, role, company, jti, token, supervised_customers, null, sign_in_count, showTutorial, otp_required_for_login, false, time_zone_name);
  }
}


export default new AuthenticationServices()
