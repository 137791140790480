import React, {useEffect, useState} from "react";
import SessionService from "../../api/SessionService";
import classNames from "classnames";
import {Redirect} from "react-router-dom";
import {TYPES_FOR_LUMU_FREE} from "../../util/CollectorsCategory";
import {useTranslation} from "react-i18next";
import {Button, DropdownItem, DropdownMenu, DropdownToggle, UncontrolledDropdown} from "reactstrap";
import {useGetTechnologies} from "../../api/hooks/onBoarding/useGetTechnologies";

const SetupQuestionnaire = ({history, setShowError}) => {
  const {t} = useTranslation();
  const technologies = useGetTechnologies();
  const isMspUser = SessionService.isMspUser();
  const [data, setData] = useState({
    able: 'placeholder',
    collect: {label: 'placeholder', value: ''},
    connect: {label: 'placeholder', value: ''},
    docs: {},
    provider: ''
  });
  const q1Options = [{label: t('able to')}, {label: t('not able to')}];
  const q2Options = [{label: t('firewall')}, {label: t('proxy')}, {label: t('DNS')}];
  const [q3Options, setOptions] = useState([]);
  const [isEnable, enableNext] = useState(false);

  useEffect(() => {
    if (technologies.isError) {
      setShowError(true);
    }
  }, [technologies.isLoading])

  if (isMspUser) {
    return <Redirect to='/'/>
  }

  const onAbleChange = (e) => {
    if (e === 'not able to') {
      enableNext(true);
      setData({
        ...data,
        able: e,
        docs: {id: '420518000000925003', url: 'https://docs.lumu.io/portal/en/kb/articles/add-a-public-gateway'}
      });
    } else if (data.connect){
      enableNext(true);
      setData({...data, able: e});
    } else {
      enableNext(false);
      setData({...data, able: e});
    }
  }

  const onCollectChange = (e) => {
    enableNext(false);
    if (e !== 'DNS') {
      const types = [...TYPES_FOR_LUMU_FREE];
      const collector = types.find(v => v.label === e);
      const supportedDevices = technologies.fulfilled ? technologies.data["collector-types"][collector.value].supported_devices : [];
      const descriptions = supportedDevices.filter((value, index, self) =>
          index === self.findIndex((t) => (
            t.description === value.description
          ))
      );
      const options = descriptions.reduce((acc, el) => {
        const docs = el.id ? {id: el.id, url: el.url} : {
          id: '420518000005229029',
          url: 'https://docs.lumu.io/portal/en/kb/articles/configure-va-collectors'
        };
        acc.push({label: el.description, docs, provider: el.description});
        return acc;
      }, []);
      setOptions(options);
      setData({...data, collect: collector, connect: {label: 'placeholder', value: ''}});
    } else {
      const supportedDevicesDNSQueries = technologies.fulfilled ? technologies.data["collector-types"]['dns-queries'].supported_devices : [];
      const supportedDevicesDNSPackets = technologies.fulfilled ? technologies.data["collector-types"]['dns-packets'].supported_devices : [];
      const docsDNSQueries = {id: supportedDevicesDNSQueries[0].id, url: supportedDevicesDNSQueries[0].url};
      const docsDNSPackets = {id: supportedDevicesDNSPackets[0].id, url: supportedDevicesDNSPackets[0].url};
      const providerDNSQueries = supportedDevicesDNSQueries[0].description;
      const providerDNSPackets = supportedDevicesDNSPackets[0].description;
      const dnsOptions = [
        {
          label: t('use Lumu as my primary network DNS resolver'),
          value: '',
          docs: {id: '420518000012912043', url: 'https://docs.lumu.io/portal/en/kb/articles/lumu-va-dns-resolver'},
          provider: ''
        },
        {
          label: t('collect metadata from my DNS server'),
          value: 'dns-queries',
          docs: docsDNSQueries,
          provider: providerDNSQueries
        },
        {
          label: t('forward metadata from my DNS logs'),
          value: 'dns-packets',
          docs: docsDNSPackets,
          provider: providerDNSPackets
        }
      ];
      setOptions(dnsOptions);
      setData({...data, collect: {label: e, value: e}, connect: {label: 'placeholder', value: ''}});
    }
  }

  const onConnectChange = (label, value, docs, provider) => {
    setData({...data, connect: {label, value}, docs, provider});
    enableNext(true);
  }

  const onNext = () => {
    if (data.able === 'not able to') {
      history.push({pathname: '/start/gateways', state: data});
    } else if (data.collect.value === 'firewall-log') {
      history.push({pathname: '/start/appliance-firewall', state: data})
    } else if (data.collect.value === 'proxy-log') {
      history.push({pathname: '/start/appliance-proxy', state: data})
    } else if (data.collect.value === 'DNS') {
      history.push({pathname: '/start/appliance-dns', state: data})
    }
  }

  const onSkip = () => history.push('/start/advance-mode');

  const QuestionSelector = ({onChange, options, selected}) => (
    <UncontrolledDropdown>
      <DropdownToggle className={"bg-transparent border-0 border-bottom-primary no-focus text-primary"} caret>
        <span className={classNames({"text-light": selected === 'placeholder'})}>{selected}</span>
      </DropdownToggle>
      <DropdownMenu className="min-w-auto mt-2 rounded">
        {options.map((op, idx) => (
          <DropdownItem key={idx} id={idx} onClick={() => onChange(op.label, op.value, op.docs, op.provider)}
                        className="border-0">
            {op.label}
          </DropdownItem>
        ))}
      </DropdownMenu>
    </UncontrolledDropdown>
  )

  return (
    <div>
      <h3 className="font-weight-bold">{t('Metadata Collection Set Up')}</h3>
      <div className="min-h-120px mb-3">
        <div className="d-flex align-items-baseline mb-2">
          <span className="text-nowrap">{t('I am')}</span>
          <QuestionSelector onChange={onAbleChange} options={q1Options} selected={data.able}/>
          <span className="pl-2">{t('deploy a virtualized machine in my organization')}</span>
        </div>
        {data.able === 'able to' &&
          <>
            <div className="d-flex align-items-baseline mb-2">
              <span className="text-nowrap">{t('I want to collect')}</span>
              <QuestionSelector onChange={onCollectChange} options={q2Options} selected={data.collect.label}/>
              <span>{t('metadata')}</span>
            </div>
            {q3Options.length > 0 &&
              <>{data.collect.value !== 'DNS' ?
                <div className="d-flex align-items-baseline">
                  <span className="text-nowrap">{t('I want to connect')}</span>
                  <QuestionSelector onChange={onConnectChange} options={q3Options} selected={data.connect.label}/>
                </div> :
                <div className="d-flex align-items-baseline">
                  <span className="text-nowrap">{t('I want to')}</span>
                  <QuestionSelector onChange={onConnectChange} options={q3Options} selected={data.connect.label}/>
                </div>
              }</>}
          </>}
      </div>
      <div className="text-right">
        <Button className="mr-2 border-0" color={'ghost-dark'} onClick={onSkip}>{t('Skip')}</Button>
        <Button color={'primary'} disabled={!isEnable} onClick={onNext}>{t('Next')}</Button>
      </div>
    </div>
  )
}

export default SetupQuestionnaire;
