import React from 'react'

const Gateways = React.lazy(() => import('./views/OnBoarding/GatewaysTutorial.js'));
const ApplianceDns = React.lazy(() => import('./views/OnBoarding/ApplianceDNS.js'));
const ApplianceFirewall = React.lazy(() => import('./views/OnBoarding/ApplianceFirewall.js'));
const ApplianceProxy = React.lazy(() => import('./views/OnBoarding/ApplianceProxy.js'));

const tutorialRoutes = [
  {path: '/start/gateways', component: Gateways, exact: true},
  {path: '/start/appliance-dns', component: ApplianceDns, exact: true},
  {path: '/start/appliance-firewall', component: ApplianceFirewall, exact: true},
  {path: '/start/appliance-proxy', component: ApplianceProxy, exact: true},

  {path: '/advance-tutorial/gateways', component: Gateways, exact: true},
  {path: '/advance-tutorial/appliance-dns', component: ApplianceDns, exact: true},
  {path: '/advance-tutorial/appliance-firewall', component: ApplianceFirewall, exact: true},
  {path: '/advance-tutorial/appliance-proxy', component: ApplianceProxy, exact: true},
];

export default tutorialRoutes
