import {useEffect, useState} from 'react';
import Session from "../SessionService";

export default function useRegisteredUser(onUserChanged) {
  const [currentUser, setCurrentUser] = useState(Session.getRegisteredUser());

  useEffect(() => {
    const onUserChange = (newValue) => {
      if (onUserChanged) {
        if (onUserChanged(currentUser, newValue))
          setCurrentUser(newValue)
      } else if (newValue) {
        setCurrentUser(newValue);
      }
    };

    Session.addUserChangeListener(onUserChange);

    return () => {
      Session.removeUserChangeListener(onUserChange);
    };
  }, []);


  return currentUser;
}
