import React, {useState} from 'react'
import {Col, FormFeedback, Input, Row} from "reactstrap";
import classNames from "classnames";
import PropTypes from "prop-types";
import isIP from 'is-ip';
import isCidr from 'is-cidr';
import {validateEmail} from "./Validations";
import {useTranslation} from "react-i18next";

export const EmailInput = ({onChange, ...props}) => (
  <Input {...props} onChange={(e) => onChange(e, validateEmail(e.target.value.trim()))}/>
)

EmailInput.propTypes = {
  onChange: PropTypes.func.isRequired
}


const passwordInputPropTypes = {
  invalidText: PropTypes.string
};
const passwordInputDefaultProps = {};

export class PasswordInput extends React.Component {
  constructor(props) {
    super(props);

    this.toggle = this.toggle.bind(this);
    this.state = {
      showText: false
    };
  }

  toggle() {
    this.setState({
      showText: !this.state.showText
    });
  }

  iconClassNames = () => {
    return classNames('fa', {
      'fa-eye fa-lg': !this.state.showText,
      'fa-eye-slash fa-lg': this.state.showText
    });
  };

  render() {
    const {className = "", invalidText, ...props} = this.props
    let classNames = ["pr-5", className].join(" ");

    return (
      <Row>
        <Col>
          <Input {...props} type={this.state.showText ? 'text' : 'password'} className={classNames}/>
          <span className="password_revealer" onClick={this.toggle}>
            <i className={this.iconClassNames()} aria-hidden="true"/>
          </span>
          {invalidText && <div id="password-alert" className="invalid-feedback">{invalidText}</div>}
        </Col>
      </Row>
    );
  }
}

PasswordInput.propTypes = passwordInputPropTypes;
PasswordInput.defaultProps = passwordInputDefaultProps;

export const IpInput = ({onChange, ...props}) => {
  const handleChangeValue = (e) => {
    let inputValue = e.target.value.trim();
    if (isIP(inputValue))
      onChange(e, isIP(inputValue), '');
    else if (isCidr.v4(inputValue)) {
      let v4 = inputValue.split('/');
      onChange(e, (v4[1] > 28 && v4[1] < 33), (v4[1] > 28 && v4[1] < 33) ? '' : 'Mask value must be between 29 and 32');
    } else if (isCidr.v6(inputValue)) {
      let v6 = inputValue.split('/');
      onChange(e, (v6[1] > 57 && v6[1] < 129), (v6[1] > 28 && v6[1] < 33) ? '' : 'Mask value must be between 58 and 128');
    } else onChange(e, isIP(inputValue), '');

  };
  return (
    <Input {...props} onChange={handleChangeValue}/>
  )
};

IpInput.propTypes = {
  onChange: PropTypes.func.isRequired
};

export const PrivateKeyInput = (props) => {
  const {t} = useTranslation();
  const [showText, setShowText] = useState(false);
  const {isInvalid, handleSecretKey, value, placeHolder, errorMessage, size, autoComplete = "new-password"} = props

  const FeedbackContent = props.Feedback
    ? props.Feedback
    : <>{errorMessage}</>

  const toggle = () => setShowText(!showText);

  return (
    <Row>
      <Col>
        <Input type={showText ? 'text' : 'password'} bsSize={size} autoComplete={autoComplete} value={value}
               className={`place-holder-Roboto text-monospace pr-5 ${props.className}`} onChange={handleSecretKey}
               placeholder={t(placeHolder)} invalid={isInvalid}/>
        <span className="password_revealer" onClick={toggle}>
            <i className={classNames('fa align-baseline', {'fa-eye fa-lg': !showText, 'fa-eye-slash fa-lg': showText})}
               aria-hidden="true"/>
        </span>
        <FormFeedback>
          {FeedbackContent}
        </FormFeedback>
      </Col>
    </Row>
  )
}
