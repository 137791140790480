import {useEffect, useReducer} from "react";
import axios from "axios";
import Session from "../../SessionService";


const reducer = (state, action) => {
  switch (action.type) {
    case 'INIT':
      return {
        ...state,
        isLoading: true,
        __forceRefresh: false
      };

    case 'SUCCESS':
      return {
        ...state,
        isLoading: false,
        fulfilled: true,
        pagination: action.payload[0],
        appliances: action.payload[1],
        __forceRefresh: false
      };

    case 'FAILURE':
      return {
        ...state,
        isLoading: false,
        isError: true,
        __forceRefresh: false
      };

    case 'REFRESH':
      return {
        isLoading: true,
        isError: false,
        fulfilled: false,
        appliances: null,
        __forceRefresh: true
      };

    default:
      return {...state}
  }
};

export default function useVirtualApplianceList(companyId, page, maxItems) {
  const currentUser = Session.getRegisteredUser();
  const [state, dispatch] = useReducer(reducer, {
    isLoading: true,
    isError: false,
    fulfilled: false,
    pagination: null,
    appliances: null,
    __forceRefresh: true
  });

  useEffect(() => {
    if (state.__forceRefresh) {
      (async () => {
        dispatch({type: 'INIT'});

        try {
          const result = await axios.get(
            `/api/v1/companies/${companyId}/appliances`,
            {
              headers: currentUser.authenticationHeaders,
              params: {items: maxItems, page}
            }
          );

          dispatch({type: 'SUCCESS', payload: result.data})
        } catch (e) {
          dispatch({type: 'FAILURE'})
        }
      })()
    }
  }, [page, maxItems, state.__forceRefresh]);

  return [{...state}, () => dispatch({type: 'REFRESH'})]
}
