import React, {useState} from "react";
import queryString from "query-string";
import {Alert, Button, Form, FormGroup} from "reactstrap";
import {useTranslation, withTranslation} from "react-i18next";
import {Link, Redirect, useLocation} from "react-router-dom";

import {PrivateKeyInput} from "../../util/Forms";
import Session from "../../api/SessionService";
import {PasswordMsg} from "../../util/errorMessages";
import {validatePassword} from "../../util/Validations";

export const ResetPasswordForm = () => {
  const location = useLocation();
  const queryValues = queryString.parse(location.search);
  const resetToken = queryValues['reset_password_token'];

  const [password, setPassword] = useState('');
  const [passwordConfirmation, setPasswordConfirmation] = useState('');
  const [step, setStep] = useState("Initial");
  const [isPosting, setIsPosting] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const [validation, setValidation] = useState({errorMsg: '', invalidPass: false, invalidConfPass: false});
  const [isPristine, setIsPristine] = useState(true);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsPristine(false)
    const formValidation = validatePassword(password, passwordConfirmation, false);
    setValidation(formValidation);

    if (!formValidation.invalidPass && !formValidation.invalidConfPass && resetToken) {
      setIsPosting(true);
      setErrorMessage(null);

      try {
        await Session.resetPassword(password, passwordConfirmation, resetToken);
        setStep('success');
      } catch (e) {
        if (e.response?.status !== 500) {
          const errors = e.response.data?.errors;
          if (errors['reset_password_token']) {
            setStep('NotAuthorized');
          } else if (errors['password']) {
            setErrorMessage(`Password ${errors['password'][0]}`)
          }
        } else {
          setStep('OperationFailed')
          setErrorMessage('Something unexpected just happened.  Please try again in a few minutes')
        }
      } finally {
        setIsPosting(false);
      }
    }
  };

  const onPasswordChange = (e) => {
    setPassword(e.target.value);
    const validation = validatePassword(e.target.value, passwordConfirmation, isPristine);
    setValidation(validation);
  }

  const onConfirmationChange = (e) => {
    setPasswordConfirmation(e.target.value);
    const validation = validatePassword(password, e.target.value, isPristine);
    setValidation(validation);
  }


  if (!resetToken)
    return <Redirect to='/'/>;

  return (
    <div>
      {{
        Initial: <ResetForm onPasswordChange={onPasswordChange} onConfirmationChange={onConfirmationChange}
                            password={password} passwordConfirmation={passwordConfirmation} validation={validation}
                            handleSubmit={handleSubmit} errorMessage={errorMessage} isPosting={isPosting}/>,
        success: <SuccessfulReset/>,
        NotAuthorized: <FailedReset title={'Password change failed'} showGotoLogin={true}
                                    message={'Your account may be blocked or the link you are using is already expired'}/>,
        OperationFailed: <FailedReset title={'Password change failed'} showGotoLogin={false} message={errorMessage}/>
      }[step]}
    </div>
  )
}

const ResetForm = ({
                     errorMessage,
                     handleSubmit,
                     password,
                     validation,
                     onPasswordChange,
                     passwordConfirmation,
                     onConfirmationChange,
                     isPosting
                   }) => {
  const {t} = useTranslation();

  const Feedback = () => {
    if (validation.errorMsg === 'validate_password') {
      return <PasswordMsg/>
    }

    return (
      <>{validation.errorMsg}</>
    )
  }

  return (
    <div>
      {errorMessage && <Alert color="warning">{t(errorMessage)}</Alert>}
      <h2 className="font-weight-bold mb-4">{t('Create your new password')}</h2>
      <Form onSubmit={handleSubmit} className="" noValidate>
        <FormGroup>
          <PrivateKeyInput placeHolder={'Password'} value={password} size={'lg'} isInvalid={validation.invalidPass}
                           handleSecretKey={onPasswordChange}/>
        </FormGroup>
        <FormGroup>
          <PrivateKeyInput placeHolder={'Confirm Password'} value={passwordConfirmation} size={'lg'}
                           isInvalid={validation.errorMsg} Feedback={<Feedback/>}
                           handleSecretKey={onConfirmationChange}/>
        </FormGroup>
        <Button color="primary" size={'lg'} block className="px-4">
          {isPosting &&
            <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"/>}
          <span>{t('Submit')}</span>
        </Button>
        <br/>
      </Form>
      <br/>
      <br/>
      <br/>
    </div>
  )
}

const SuccessfulResetComponent = ({t}) => {
  return (
    <div>
      <h2 className="font-weight-bold mb-4">{t('Password Changed!')}</h2>
      <p className="font-weight-normal">
        {t('Your Password has been changed successfully.')}
      </p>
      <br/>
      <Button color="primary" tag={Link} to="/account/login">{t('Return to login')}</Button>
      <br/>
      <br/>
      <br/>
    </div>
  );
};

const SuccessfulReset = withTranslation()(SuccessfulResetComponent);

const FailedResetComponent = ({title, message, showGotoLogin, tryAgainLink}) => {
  const {t} = useTranslation();

  return (
    <div>
      <h2 className="font-weight-bold text-danger mb-4">{t(title)}</h2>
      <p className="font-weight-bold">
        {t(message)}
      </p>
      {tryAgainLink && <a href={tryAgainLink} className="btn btn-primary">Try Again</a>}
      <br/>
      {showGotoLogin && <Button color="primary" tag={Link} to="/account/login">{t('Return to login')}</Button>}
      <br/>
      <br/>
      <br/>
    </div>
  );
};


FailedResetComponent.defaultProps = {
  showGotoLogin: false,
  tryAgainLink: null
};

const FailedReset = withTranslation()(FailedResetComponent);
