import {RegisterMessagesLayout} from "../RegisterMessagesLayout";
import {Button} from "reactstrap";
import {useHistory, useLocation} from "react-router-dom";
import React, {useState} from "react";
import {Trans, useTranslation} from "react-i18next";
import Session from "../../../api/SessionService";
import useRegisteredUser from "../../../api/hooks/useRegisteredUser";
import {AWSApi} from "../../../api/AWSApi";
import {openDocTab} from "../../../util/documentation";

export const AWSAccountConfirmation = () => {
  const {t} = useTranslation();
  const history = useHistory();
  const currentUser = useRegisteredUser();
  const location = useLocation();
  const awsUuid = location.state?.awsUuid;
  const awsStatus = location.state?.awsStatus;
  const statusCode = location.state?.statusCode ?? null;
  const [isPosting, setIsPosting] = useState(false);
  const [errorCode, setErrorCode] = useState(statusCode);

  window.history.replaceState({}, document.title);

  if(!location.state){
    history.push("/");
    return null;
  }

  const handleLogout = async () => {
    Session.logout().then();
    history.push("/account/login");
  };

  const handleAssociateAccount = () => {
    setIsPosting(true);
    AWSApi(currentUser, awsUuid)
      .then(r => {
        setIsPosting(false);
        if (r.awsSubscriptionSuccess) {
          history.push({
            pathname: '/dashboard',
            state: {awsSubscriptionSuccess: r.awsSubscriptionSuccess}
          });
          return null;
        }
        setErrorCode(r.statusCode);
      })
      .catch(r => setErrorCode(r.statusCode))
  }

  const goToDashboard = () => history.push('/');

  const goToLogin = () => {
    Session.logout().then();
    history.push("/account/login");
  }

  const MessageView = (code) => {
    const isAGeneralError = code !== null && code !== 422 && code >= 400;
    const parseCode = isAGeneralError ? 500 : code;
    switch (parseCode) {
      case null:
        return <AlreadyAssociate btnTitle='Go to Dashboard' handleClick={goToDashboard}/>;
      case 422:
        return <AlreadyAssociate btnTitle='Go to Login' handleClick={goToLogin}/>;
      case 500:
        return <ErrorMessage handleLogout={handleLogout}/>;
      default:
        return <SessionActive currentUser={currentUser} handleAssociateAccount={handleAssociateAccount}
                              handleLogout={handleLogout} isPosting={isPosting}/>;
    }
  }

  if(awsStatus === 'error'){
    if(awsUuid === 'associated'){
      return (
        <RegisterMessagesLayout className="max-w-488px">
          <AlreadyAssociate btnTitle='Go to Login' handleClick={goToLogin}/>
        </RegisterMessagesLayout>
      )
    }
    return (
      <RegisterMessagesLayout className="max-w-488px">
        <ErrorMessage handleLogout={handleLogout}/>
      </RegisterMessagesLayout>
    )
  }

  if (awsUuid && awsStatus && !errorCode) {
    return (
      <RegisterMessagesLayout className="max-w-488px">
        <SessionActive currentUser={currentUser} handleAssociateAccount={handleAssociateAccount}
                       handleLogout={handleLogout} isPosting={isPosting}/>
      </RegisterMessagesLayout>
    )
  }

  if (awsUuid) {
    return (
      <RegisterMessagesLayout className="max-w-488px">
        {MessageView(errorCode)}
      </RegisterMessagesLayout>
    );
  }
}

const SessionActive = ({currentUser, handleLogout, handleAssociateAccount, isPosting}) => {
  const {t} = useTranslation();

  return (
    <>
      <h4 className="font-weight-semi-bold mb-4">{t('Associate account')}</h4>
      <span className="text-yellow">{t('You already have a session open for the user')}</span>
      <span className="mb-3 text-yellow font-weight-medium">{currentUser.email}</span>
      <p className="mb-4 text-yellow">
        {t('Do you want to associate this account with your AWS Marketplace subscription?')}
      </p>
      <div>
        <Button color="dark" className="mr-3 fnt-16" outline onClick={handleLogout}>{t('No, log out')}</Button>
        <Button color="primary" className="rounded fnt-16" onClick={handleAssociateAccount} disabled={isPosting}>
          {isPosting && <span className="spinner-border spinner-border-sm mr-1" role="status" aria-hidden="true"/>}
          <span>{t('Yes, associate subscription')}</span>
        </Button>
      </div>
    </>
  )
}

const AlreadyAssociate = ({btnTitle, handleClick}) => {
  const {t} = useTranslation();

  return (
    <>
      <h4 className="font-weight-semi-bold mb-4">{t('Subscription already associated')}</h4>
      <span
        className="mb-3">{t('It seems that your AWS subscription is already associated with another Lumu account')}</span>
      <p className="mb-4">
        <Trans
          i18nKey={'If you need help please contact us'}>
          If you need help please <a
          className="text-decoration-underline text-primary pointer"
          onClick={() => openDocTab('TICKETS', 'https://docs.lumu.io/portal/en/kb/articles/contact-us')}>contact
          us.</a>
        </Trans>
      </p>
      <div>
        <Button color="primary" block className="rounded fnt-16" onClick={handleClick}>
          <span>{t(btnTitle)}</span>
        </Button>
      </div>
    </>
  )
}

const ErrorMessage = ({handleLogout}) => {
  const {t} = useTranslation();

  return (
    <>
      <h4 className="font-weight-semi-bold mb-4">{t('AWS Subscription Failed')}</h4>
      <span
        className="mb-3">{t('It seems that something went wrong while confirming your subscription')}</span>
      <p className="mb-4">{t('Please check your configuration in AWS Marketplace and try again')}</p>
      <div>
        <Button color="primary" block className="rounded fnt-16" onClick={handleLogout}>
          <span>{t('Go to Login')}</span>
        </Button>
      </div>
    </>
  )
}
