import {geolocateApi} from "../api/hooks/useIPGeolocation";

let url;
const setCountry = async (name, email) => {
  const res = await geolocateApi()
  const country = res?.data?.location?.country
  return `https://lumu.io/meet?Name=${name}&Email=${email}&Country=${country}`;
}

export const meetLink = (user) => {
  const name = user.name;
  const email = user.email;
  let country = user.getCompany().provided_country ?? user.getCompany().ip_country;
  if (!country) {
    setCountry(name, email).then(r => url = r);
    return url
  }else {
    return `https://lumu.io/meet?Name=${name}&Email=${email}&Country=${country}`;
  }
}
