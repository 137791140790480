import React, {useState} from 'react';
import {Trans, useTranslation} from "react-i18next";
import {Link, Redirect} from "react-router-dom";
import Session from "../../api/SessionService";
import {Alert, Button, Form, FormGroup, Input, Label} from "reactstrap";
import {useLoginContext} from "../../util/context/LoginContext";
import {AWSApi} from "../../api/AWSApi";

const Backup2FA = ({history, location}) => {
  const {t} = useTranslation();
  const {awsUuid} = useLoginContext();
  const [otp, setOtp] = useState('');
  const {email, password} = location.state;
  const [showError, setError] = useState(false);
  const [isPosting, setIsPosting] = useState(false);

  if (!location.state || !location.state.email || !location.state.password) {
    return <Redirect to={'/'}/>
  }

  const onOtpChange = (e) => setOtp(e.target.value);

  const onVerify = (e) => {
    e.preventDefault();
    setIsPosting(true);
    Session.signIn(email, password, otp)
      .then(user => {
        return AWSApi({...user, is2FA: false}, awsUuid);
      })
      .then(res => {
        if (!res.awsSubscriptionSuccess && res.statusCode) {
          const code = res.statusCode === 422 ? null : res.statusCode;
          history.push({
            pathname: '/aws-confirmation',
            state: {awsSubscriptionSuccess: res.awsSubscriptionSuccess, awsUuid, statusCode: code}
          });
          return null;
        }
        if(res.awsSubscriptionSuccess){
          history.push({
            pathname: '/dashboard',
            state: {awsSubscriptionSuccess: res.awsSubscriptionSuccess}
          });
          return null;
        }
        if (res.data) {
          setError(true);
          setIsPosting(false);
        } else {
          history.push('/');
        }
      })
      .catch(_ => {
        setIsPosting(false);
        setError(true)
      })
  }

  return (
    <Form onSubmit={onVerify}>
      {showError && <Alert color="danger">{
        <Trans i18nKey={'verify_code_error'}>
          The code that you entered is not valid. Please try again. If the error persists,
          <a className="font-weight-bold text-danger" href="mailto:support@lumu.io">
            contact our support team.
          </a>
        </Trans>
      }</Alert>}
      <h2 className="font-weight-bold">{t('Two-Factor Authentication')}</h2>
      <p className={"mb-3"}>
        {t('You will enter using one of the backup codes that you received when you activated the second factor. Remember that once you log in using this code, it cannot be used again')}
      </p>
      <FormGroup>
        <Label for={'otp-id'}>{t('Backup Code')}</Label>
        <Input type="text" id={'otp-id'} placeholder={t('Please enter your backup code here')} onChange={onOtpChange}
               value={otp}/>
      </FormGroup>
      <Button color="primary" className="px-4" block disabled={isPosting}>
        {isPosting && <span className="spinner-border spinner-border-sm mr-2" role="status" aria-hidden="true"/>}
        <span>{t('Use Backup Code')}</span>
      </Button>
      <Button color="white" tag={Link} to="/account/login" className="px-4 no-focus" block>
        {t('Cancel')}
      </Button>
    </Form>
  )
}

export default Backup2FA;
