import React, {useEffect, useRef, useState} from 'react';
import {Link, useHistory, useLocation} from 'react-router-dom';
import {Alert, Button, Form, FormFeedback, FormGroup, Label} from 'reactstrap';
import {Trans, useTranslation} from 'react-i18next';
import queryString from "query-string";

import Session from "../../api/SessionService";
import {EmailInput, PasswordInput} from "../../util/Forms";
import ReactGA from "react-analytics-ga4";
import {getTierName} from "../../util/BillingTier";
import {useLoginContext} from "../../util/context/LoginContext";
import {LoginLayoutAWS} from "./aws/LoginLayoutAWS";
import LoginLayout from "./LoginLayout";
import {openDocTab} from "../../util/documentation";
import {AWSApi} from "../../api/AWSApi";
import {RegisterMessagesLayout} from "./RegisterMessagesLayout";

const LoginForm = () => {
  const history = useHistory();
  const location = useLocation();
  const {awsUuid, setAwsUuid, awsStatus, setAwsStatus} = useLoginContext();
  const [isPristine, setIsPristine] = useState(true);
  const {msg} = location.state ? location.state : {};
  const statusCodeAws = location.state?.statusCode ?? null;
  const fromRegisterForm = location.state?.registerForm ?? null;
  const [email, setEmail] = useState({value: '', isValid: false});
  const [password, setPassword] = useState('');
  const [isPosting, setIsPosting] = useState(false);
  const [errorMessage, setErrorMessage] = useState(msg);
  const formRef = useRef(null);
  const {t} = useTranslation();
  const referrer = location.state?.referrer ?? null;
  const search = location.state?.search ?? null;
  const queryParams = queryString.parse(location.search);
  window.history.replaceState({}, document.title);

  useEffect(() => {
    window.ZohoHCAsapReady(function () {
      window.ZohoHCAsap.Action("close");
    });
  }, [])

  useEffect(() => {
    if (queryParams["so"]) {
      switch (queryParams["so"]) {
        case "401":
          setErrorMessage("Authentication failed");
          break;
        case "409":
          setErrorMessage("Single sign-on failed. Please verify your configuration");
          break;
        default:
          setErrorMessage("Unexpected failure");
      }

      delete queryParams["so"];
      const search = queryString.stringify(queryParams);
      history.replace({
        search: search.toString(),
      });
    }
  }, []);

  const handleSubmit = (e) => {
    e.preventDefault();
    let form = formRef.current;
    setIsPristine(false);

    if (email.isValid && !!password) {
      setIsPosting(true);
      setErrorMessage(null);

      Session.signIn(email.value, password, null, awsUuid)
        .then(user => {
          return AWSApi(user, awsUuid);
        })
        .then(res => {
          if (awsUuid && res.isCustomerManager) {
            setIsPosting(false);
            setErrorMessage("Invalid account. You can not associate a subscription using a customer manager account");
            Session.logout().then();
            history.push("/account/login");
            return null;
          }
          if (res.awsSubscriptionSuccess) {
            history.push({
              pathname: '/dashboard',
              state: {awsSubscriptionSuccess: res.awsSubscriptionSuccess}
            });
            return null;
          }
          if (res.is2FA) {
            history.push({pathname: '/account/login/2fa', state: {email: email.value, password}});
            return null;
          }
          if (awsUuid && !res.awsSubscriptionSuccess) {
            const code = res.statusCode === 422 ? null : res.statusCode;
            history.push({
              pathname: '/aws-confirmation',
              state: {awsSubscriptionSuccess: res.awsSubscriptionSuccess, awsUuid, statusCode: code}
            });
            return null;
          }
          ReactGA.set("user_properties", {
            dimension1: res.uid,
            dimension2: res.company.uuid,
            dimension3: getTierName(res.company.settings.billing_tier).value,
            dimension4: res.company.name
          });
          setIsPosting(false);

          if (referrer) {
            history.push({
              pathname: referrer,
              search
            });
          } else {
            history.push('/');
          }
        })
        .catch(r => {
          setIsPosting(false);
          if (r.status === 302 && r.data.redirect_url) {
            form.action = r.data.redirect_url;
            form.submit();
          } else if (r.data?.error) {
            setErrorMessage(r.data.error);
          } else {
            setErrorMessage(t("Something went wrong! Please try again later"));
          }
        });
    }
  };

  if (awsStatus === 'error') {
    if (awsUuid === 'associated') {
      return (
        <RegisterMessagesLayout className="max-w-488px">
          <ErrorMessage statusCodeAws={422} setAwsUuid={setAwsUuid} setAwsStatus={setAwsStatus}/>
        </RegisterMessagesLayout>
      )
    }
    return (
      <RegisterMessagesLayout className="max-w-488px">
        <ErrorMessage statusCodeAws={500} setAwsUuid={setAwsUuid} setAwsStatus={setAwsStatus}/>
      </RegisterMessagesLayout>
    )
  }

  if (statusCodeAws) {
    return (
      <RegisterMessagesLayout className="max-w-488px">
        <ErrorMessage statusCodeAws={statusCodeAws} setAwsUuid={setAwsUuid} setAwsStatus={setAwsStatus}
                      registerForm={fromRegisterForm}/>
      </RegisterMessagesLayout>
    )
  }

  if (awsUuid) {
    return (
      <LoginLayoutAWS className='p-4'>
        <FormLayout isPosting={isPosting} email={email} setEmail={setEmail} errorMessage={errorMessage}
                    handleSubmit={handleSubmit} isPristine={isPristine} password={password} setPassword={setPassword}>
          <h5 className="font-weight-semi-bold mb-4">{t('Login to your account')}</h5>
        </FormLayout>
        <div className="divider d-flex align-items-center mt-4 mb-4">
          <hr className="float-left m-0 w-50"/>
          <span className="mx-2 fnt-14 text-dark-light">{t('or')}</span>
          <hr className="float-right m-0 w-50"/>
        </div>
        <Button id="btn-create-account" color="dark" className="px-4" block
                onClick={() => history.push('/account/sign-up')}>
          <span className="fnt-16 font-weight-medium">{t('Create an account')}</span>
        </Button>
      </LoginLayoutAWS>
    )
  }

  return (
    <LoginLayout>
      <div>
        <div id="ssoForm" style={{display: "none"}}>
          <form ref={formRef} method={"post"} action={"/login"}>
            <input readOnly value={email.value} name="email"/>
          </form>
        </div>
        <FormLayout isPosting={isPosting} email={email} setEmail={setEmail} errorMessage={errorMessage}
                    handleSubmit={handleSubmit} isPristine={isPristine} password={password}
                    setPassword={setPassword}>
          <h2 className="font-weight-bold mb-4">{t('Login with your account information')}</h2>
        </FormLayout>
        <Button color="link" disabled={isPosting} tag={Link} to="/account/recover"
                className="text-muted px-0 mb-5">
          {t('Forgot your password')}
        </Button>
        <span className="btn text-muted px-0 mb-5 mx-2">{'|'}</span>
        <Button color="link" disabled={isPosting} tag={Link} to="/account/login/sso"
                className="text-muted px-0 mb-5">
          {t('Single Sign-on')}
        </Button>
        <br/>
        <span className="text-muted px-0">{t('dont_have_an_account_yet')}</span>
        <Link to="/account/sign-up" className="pt-0 pl-2">{t('Open an account')}</Link>
      </div>
    </LoginLayout>
  );
};

const FormLayout = ({
                      children,
                      handleSubmit,
                      errorMessage,
                      setEmail,
                      email,
                      isPristine,
                      setPassword,
                      password,
                      isPosting
                    }) => {
  const {t} = useTranslation();

  return (
    <Form onSubmit={handleSubmit} className="" noValidate>
      {!!errorMessage && <Alert id="alert-warning" color="warning">{t(errorMessage)}</Alert>}
      {children}
      <FormGroup>
        <Label for={'email-field'}>{t('Corporate Email')}</Label>
        <EmailInput id="email-field" type="text" name="email" autoComplete="email" placeholder="email@address.com"
                    onChange={(e, isValid) => setEmail({value: e.target.value.trim(), isValid: isValid})}
                    invalid={(!isPristine && !email.isValid)} value={email.value}/>
        <FormFeedback>
          {t('Please enter a valid email')}
        </FormFeedback>
      </FormGroup>
      <FormGroup>
        <Label for={'password-field'}>{t('Password')}</Label>
        <PasswordInput id="password-field" name="password" autoComplete="current-password" placeholder="Password"
                       onChange={(event) => setPassword(event.target.value)} value={password}
                       invalidText={t('Please enter a password')} invalid={(!isPristine && !password)}/>
      </FormGroup>
      <Button id="btn-login" color="primary" className="px-4" block disabled={isPosting}>
        {isPosting &&
          <div className='spinner-border spinner-border-sm mr-2' role="status" aria-hidden="true"/>
        }
        <span>{t('Login')}</span>
      </Button>
    </Form>
  )
}

export default LoginForm;

const ErrorMessage = ({statusCodeAws, setAwsUuid, setAwsStatus, registerForm}) => {
  const {t} = useTranslation();
  const history = useHistory();
  const title = statusCodeAws === 422 ? 'Subscription already associated' : 'AWS Subscription Failed';

  const goToLogin = () => {
    setAwsStatus(null);
    setAwsUuid(null);
    history.push('/account/login');
  }

  return (
    <>
      <h4 className="font-weight-semi-bold mb-4">{t(title)}</h4>
      {statusCodeAws === 422 ?
        <>
          {registerForm ?
            <span className="mb-3">
              {t('Your Lumu account was not created because it seems that your AWS subscription is already associated with another Lumu account')}
            </span> :
            <span className="mb-3">
              {t('It seems that your AWS subscription is already associated with another Lumu account')}
            </span>
          }
          <p className="mb-4">
            <Trans
              i18nKey={'If you need help please contact us'}>
              If you need help please <a
              className="text-decoration-underline text-primary pointer"
              onClick={() => openDocTab('TICKETS', 'https://docs.lumu.io/portal/en/kb/articles/contact-us')}>contact
              us.</a>
            </Trans>
          </p>
        </>
        :
        <>
        <span className="mb-3">
          {t('It seems that something went wrong while confirming your subscription')}
        </span>
          <p className="mb-4">{t('Please check your configuration in AWS Marketplace and try again')}</p>
        </>
      }
      <div>
        <Button color="primary" block className="rounded fnt-16" onClick={goToLogin}>
          <span>{t('Go to Login')}</span>
        </Button>
      </div>
    </>
  )
}
