import React from 'react';
import {Button, Col, Container, Row} from "reactstrap";
import {LogoHeader} from "./LogoHeader";
import {Trans, useTranslation} from "react-i18next";
import {CardAdvanceMode} from "./CardAdvanceMode";
import {openDocsByIdUrl} from "../../util/documentation";
import SessionService from "../../api/SessionService";

const StartAdvanceMode = ({history}) => {
  const {t} = useTranslation();
  const user = SessionService.getRegisteredUser();
  const badgesVA = ['Proxy Log', 'Firewall Log', 'DNS', 'Netflow'];

  if (!user.show_tutorial) {
    history.push('/dashboard');
  }

  const onClick = (path) => {
    history.push(`${path}`);
  }

  const SelectButton = ({path}) => (
    <Button color={'dark'} outline className={"rounded btn-block px-5"} onClick={() => onClick(path)}>
      Select
    </Button>
  )

  return (
    <div>
      <LogoHeader/>
      <div className="tutorial-height bg-gray-f2">
        <Container fluid className="pt-5 d-flex flex-column tutorial-height justify-content-between">
          <Row className="mt-4 justify-content-center align-items-center">
            <Col md={8}>
              <h3>{t('Ready to go? Start sending network metadata')}</h3>
              <CardAdvanceMode badges={['DNS']} button={<SelectButton path={'/start/gateways'}/>} time='10 min avg'
                               title='Use Lumu as my outbound DNS resolver'>
                <Trans i18nKey={'advance_gateways_explain'}>
                  Using Gateways is is the easiest and fastest way to implement Lumu in your organization. You must have
                  access to
                  configure one or more devices to use the <a className="text-primary pointer text-decoration-underline"
                                                              onClick={() => openDocsByIdUrl('420518000000925003', 'https://docs.lumu.io/portal/en/kb/articles/add-a-public-gateway')}>
                  public DNS we provide.</a>
                </Trans>
              </CardAdvanceMode>
              <CardAdvanceMode badges={badgesVA} button={<SelectButton path={'/start/advance-mode/appliances'}/>}
                               time='60 to 90 min avg'
                               title='Use Lumu to collect network metadata logs using a Virtual Appliance'>
                <Trans i18nKey={'advance_appliance_explain'}>
                  A <a className="text-primary pointer text-decoration-underline"
                       onClick={() => openDocsByIdUrl('420518000000806089', 'https://docs.lumu.io/portal/en/kb/articles/lumu-virtual-appliance')}>
                  Virtual Appliance</a> is a virtual machine that Lumu provides. This is the most complete solution
                  because it allows you to collect proxy, firewall, and DNS metadata. Use this option if you have
                  sufficient permissions on your infrastructure to deploy our virtual machine.
                </Trans>
              </CardAdvanceMode>
            </Col>
          </Row>
        </Container>
      </div>
    </div>
  )
}

export default StartAdvanceMode;
