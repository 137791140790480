import {useEffect} from "react";

const Billing = () => {

  useEffect(() => window.location.assign('/billing'), [])

  return null
}

export default Billing
