import React from "react";
import {AppNavbarBrand} from "@coreui/react";
import logo from "../../assets/img/brand/logo.png";
import {Navbar} from "reactstrap";

export const LogoHeader = () => {
  return (
    <>
      <Navbar className="px-5 border-bottom">
        <AppNavbarBrand full={{src: logo, height: 55, alt: 'Lumu Logo'}}/>
      </Navbar>
    </>
  )
}
