import {createContext, useContext, useState} from "react";

const LoginContext = createContext(null);

export const useLoginContext = () => {
  return useContext(LoginContext);
}

export const LoginProvider = ({children}) => {
  const [awsUuid, setAwsUuid] = useState(null);
  const [awsStatus, setAwsStatus] = useState(null);

  return (
    <LoginContext.Provider value={{awsUuid, setAwsUuid, awsStatus, setAwsStatus}}>
      {children}
    </LoginContext.Provider>
  )
}
