import React, {useEffect, useState} from "react";
import {Alert, Col, Container, Media, Row} from "reactstrap";
import gear from "../../assets/img/features/onboarding/gear.svg";
import SetupQuestionnaire from "./SetupQuestionnaire";
import SessionService from "../../api/SessionService";
import {Trans, useTranslation} from "react-i18next";
import {LogoHeader} from "./LogoHeader";
import {Redirect} from "react-router-dom";
import useRecordList from "../../api/hooks/useRecordList";
import useVirtualApplianceList from "../../api/hooks/virtualAppliances/useVirtualApplianceList";
import {SimpleLoading} from "../../util/loading";
import useSecActivityCounts from "../../api/hooks/useSecActivityCounts";
import {beginningOfDate, endOfDate, nowUTC, nowUTCPlusDays, toUserTimezone} from "../../util/datetime";
import {openDocTab} from "../../util/documentation";
import {meetLink} from "../../util/MeetLumuFree";
import useRegisteredUser from "../../api/hooks/useRegisteredUser";

const SetupGuide = ({history}) => {
  const {t} = useTranslation();
  const currentUser = useRegisteredUser(null);

  const companyId = currentUser ? currentUser.getCompany().id : null;
  const [showError, setShowError] = useState(false);
  const [gatewayList] = useRecordList(`companies/${companyId}/gateways`, 1, 1);
  const [applianceList] = useVirtualApplianceList(companyId, 1, 1);
  const startDate = beginningOfDate(toUserTimezone(nowUTCPlusDays(-7), currentUser.timezone));
  const endDate = endOfDate(toUserTimezone(nowUTC(), currentUser.timezone));
  const [activityCounts, refreshActivityCounts] = useSecActivityCounts([], {startDate, endDate});
  const isSendingTraffic = !activityCounts.isLoading ? (activityCounts.fulfilled && activityCounts.items.totals.All > 0) : null;

  useEffect(() => {
    refreshActivityCounts(startDate, endDate);
  }, [])

  if (!currentUser || !currentUser.show_tutorial) {
    return <Redirect to={'/dashboard'}/>
  }

  if (!currentUser) {
    return <Redirect to={'/'}/>
  }

  if (gatewayList.isLoading || applianceList.isLoading || isSendingTraffic === null) {
    return <SimpleLoading/>
  } else if (gatewayList.fulfilled && applianceList.fulfilled) {
    if (gatewayList.items.length > 0 || applianceList.appliances.length > 0 || isSendingTraffic) {
      SessionService.hideTutorial();
      return <Redirect to={{pathname: '/dashboard'}}/>
    }
  }

  const user = currentUser.name;

  return (
    <div>
      <LogoHeader/>
      <div className="tutorial-height bg-gray-f2">
        <Container fluid className="pt-5 d-flex flex-column tutorial-height justify-content-between">
          <Row className="mt-4 justify-content-center align-items-center">
            <Col lg={4}>
              {showError &&
                <Alert color="warning" className="d-flex align-items-baseline">
                  <i className="fa fa-info-circle mr-2"/>
                  <div>
                    <Trans i18nKey={'error_loading_page'}>
                      Something went wrong while loading the information. Try reloading this page or <a
                      className="pointer"
                      onClick={() => openDocTab('TICKETS', 'https://docs.lumu.io/portal/en/kb/articles/contact-us')}>
                      <u>contact
                        support team.</u></a>
                    </Trans>
                  </div>
                </Alert>}
              <div>
                <h5>
                  <Trans i18nKey={'welcome_user'} user={user}>
                    Welcome, <strong>{{user}}</strong>
                  </Trans>
                </h5>
                <p>
                  {t('By answering a couple of questions we can suggest to you a metadata collector type that best suits your organization')}
                </p>
              </div>
              <SetupQuestionnaire history={history} setShowError={setShowError}/>
            </Col>
            <Col lg={4}>
              <Media object src={gear} alt="gear-img" className="img-fluid d-none d-lg-block max-h-450px"/>
            </Col>
          </Row>
          <Row className="justify-content-center">
            <Alert color="dark">
              <i className={"fa fa-info-circle mr-2"}/>
              <Trans i18nKey={'questionnaire_schedule_call'}>
                Need help finding the right metadata collector type?
                <a href={"#"} className="text-dark font-weight-bold text-decoration-underline"
                   onClick={() => window.open(meetLink(currentUser), '_blank')}>
                  Schedule an appointment with a Lumu expert
                </a>
              </Trans>
            </Alert>
          </Row>
        </Container>
      </div>
    </div>
  )
}

export default SetupGuide;
