import {useTranslation} from "react-i18next";
import {Card, CardBody} from "reactstrap";
import {AdvanceModeBadge} from "../../util/ThreatTypeBadges";
import React from "react";

export const CardAdvanceMode = ({badges, button = [], children, time, title, id=''}) => {
  const {t} = useTranslation();

  return (
    <Card className="mb-2">
      <CardBody className="d-flex flex-column flex-sm-row justify-content-between">
        <div>
          <h4 className="font-weight-normal m-0"> {t(title)}</h4>
          <div className='d-flex align-items-center flex-wrap mb-3'>
            {badges.map((element, index) => <AdvanceModeBadge id={index} type={[element]}/>)}
            <div>
              <i className="fa fa-clock-o mx-1"/>
              <small>{time}</small>
            </div>
          </div>
          <p className="fnt-14">
            {children}
          </p>
        </div>
        <div>
          {button}
        </div>
      </CardBody>
    </Card>
  )
}
