import {Trans} from "react-i18next";
import React from "react";

export const PasswordMsg = () => (
  <Trans i18nKey="validate_password">
    Must be <span className="font-weight-semi-bold">6+ characters</span>,
    at least <span className="font-weight-semi-bold">1 number</span> and
    <span className="font-weight-semi-bold">1 symbol</span>
    <span className="fnt-11">(!@#$%^&*-_+)</span>
  </Trans>
)
