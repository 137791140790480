import React, {useState} from "react";
import {Button, Modal, ModalBody, ModalHeader, Navbar, NavbarText} from "reactstrap";
import classNames from "classnames";
import {useTranslation} from "react-i18next";
import SessionService from "../../api/SessionService";

export const DoItLaterHeader = ({history}) => {
  const {t} = useTranslation();
  const [isModalOpen, setIsModalOpen] = useState(false);

  const onClick = () => {
    toggleModal();
  }

  const goToDashboard = () => {
    SessionService.hideTutorial();
    history.replace('/dashboard');
  }

  const toggleModal = () => {
    setIsModalOpen(!isModalOpen);
  }
  return (
    <>
      <Navbar className="px-5 border-bottom justify-content-end h-75px">
        <NavbarText className={classNames("fnt-18 pointer")} onClick={onClick}>
          {t('Do it later')}
        </NavbarText>
      </Navbar>
      <Modal className="max-w-650px" isOpen={isModalOpen} toggle={toggleModal} centered>
        <ModalHeader toggle={toggleModal} className="border-0 pb-0"/>
        <ModalBody className="pt-0">
          <div className="px-5">
            <p className="fnt-24 font-weight-bold"> {t('Are you sure')}</p>
            <p className="fnt-18">
              {t("You won’t have compromise visibility until you configure your network metadata collectors")}
            </p>
            <div className="text-right mt-4 mb-3">
              <Button className="font-weight-bold px-4 fnt-18 text-dark mr-3" outline size="lg" onClick={toggleModal}>
                {t('Cancel')}
              </Button>
              <Button className="font-weight-bold px-4 text-uppercase" color="danger" onClick={goToDashboard} size="lg">
                {t('Continue without visibility')}
              </Button>
            </div>
          </div>
        </ModalBody>
      </Modal>
    </>
  )
}
