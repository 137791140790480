import {useEffect, useReducer} from "react";
import axios from "axios"

const reducer = (state, action) => {
  switch (action.type) {
    case 'INIT':
      return {
        ...state,
        isLoading: true,
        __forceRefresh: false
      };

    case 'SUCCESS':
      return {
        ...state,
        isLoading: false,
        fulfilled: true,
        data: action.payload,
        __forceRefresh: false
      };

    case 'FAILURE':
      return {
        ...state,
        isLoading: false,
        isError: true,
        __forceRefresh: false
      };

    default:
      return {
        ...state
      }
  }
};

export const useGetTechnologies = () => {
  const [state, dispatch] = useReducer(reducer, {
    isLoading: false,
    isError: false,
    fulfilled: false,
    __forceRefresh: true
  });

  useEffect(() => {
    if (state.__forceRefresh) {
      (async () => {
        dispatch({type: 'INIT'});

        try {
          const result = await axios.get(
            `https://updates.lumu.io/collector-templates/1/templates.json`
          )

          dispatch({type: 'SUCCESS', payload: result.data});
        } catch (e) {
          dispatch({type: 'FAILURE'});
        }
      })()
    }
  }, [state.__forceRefresh])

  return {...state};
}

