export const TYPES_FOR_LUMU_FREE = [
  {value: 'dns-packets', label: 'DNS Packets'},
  {value: 'dns-queries', label: 'DNS Queries'},
  {value: 'proxy-log', label: 'proxy'},
  {value: 'firewall-log', label: 'firewall'},
]

export const TYPES = [
  {value: 'dns-packets', label: 'DNS Packets'},
  {value: 'dns-queries', label: 'DNS Queries'},
  {value: 'proxy-log', label: 'Proxy Log'},
  {value: 'firewall-log', label: 'Firewall Log'},
  {value: 'netflow', label: 'Netflow/IPFIX'}
]
