import {openDocsByIdUrl, openDocTab} from "../../util/documentation";
import {Card, CardBody} from "reactstrap";
import React from "react";
import {useTranslation} from "react-i18next";

export const HelpCard = ({icon, title, subtitle, url, tabName = '', id = '', className = ''}) => {
  const {t} = useTranslation();

  const openDocs = () => {
    if (tabName) {
      openDocTab(tabName, url);
    } else {
      openDocsByIdUrl(id, url);
    }
  }

  return (
    <Card onClick={openDocs} className={`pointer ${className}`}>
      <CardBody className="d-flex">
        <i className={`fa ${icon} text-muted fa-3x mr-2`} aria-hidden="true"/>
        <div className="overflow-hidden">
          <div className="text-uppercase text-muted fnt-10">{t(title)}</div>
          <div className="font-weight-bold">{t(subtitle)}</div>
        </div>
      </CardBody>
    </Card>
  )
}

export const HelpCardAdvance = ({icon, title, subtitle, url, tabName = '', id = '', className = ''}) => {
  const {t} = useTranslation();

  const openDocs = () => {
    if (tabName) {
      openDocTab(tabName, url);
    } else {
      openDocsByIdUrl(id, url);
    }
  }

  return (
    <Card onClick={openDocs} className={`pointer ${className}`}>
      <CardBody className="d-flex">
        <i className={`fa ${icon} text-muted fa-3x mr-2`} aria-hidden="true"/>
        <div className="overflow-hidden">
          <div className="text-uppercase text-muted fnt-10">{t(title)}</div>
          <div className="font-weight-bold">{t(subtitle)}</div>
        </div>
      </CardBody>
    </Card>
  )
}
