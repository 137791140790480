import {Col, Container, Row} from "reactstrap";
import Logo from "../../assets/img/brand/logo.png";
import IncidentsBanner from "../../assets/img/brand/banner-incidents-f.png";
import React from "react";
import {DateTime} from "luxon";
import {useTranslation} from "react-i18next";


const LoginLayout = ({children}) => {
  const {t} = useTranslation();
  const DEACTIVATE_FEATURE_ON = '2021-03-01T00:00:00.001';
  const today = DateTime.local();
  const deactivationDate = DateTime.fromISO(DEACTIVATE_FEATURE_ON);
  const timeDiff = deactivationDate.diff(today, 'minute').as('minute');
  const display = timeDiff >= 0;

  return (
    <div className="app">
      <Col sm={12} className='border-bottom'>
        <img src={Logo} className="my-3 ml-5" width={'118px'} alt="logo"/>
      </Col>
      <Container fluid className="app-body m-0">
        <Col md="6" sm="12" className="align-self-center">
          <Row className="justify-content-center">
            <Col md={10} lg={8}>
              {children}
            </Col>
          </Row>
        </Col>
        {
          display ? (
            <Col md="6" className="bg-light d-none d-md-flex flex-column justify-content-center">
              <Col lg={10} md={10} sm={12} className="text-center align-self-center">
                <h1>Introducing Incidents</h1>
                <img src={IncidentsBanner} className="mb-5 img-fluid" alt="logo"/>
                <h5 className="font-weight-normal">
                  Cybersecurity can be hard, messy, complicated ...
                </h5>
                <h5 className="font-weight-normal">
                  That is why we are changing the way you and your team can manage incidents and group network segments
                  by labels according to the relevance they have on your business.
                </h5>
              </Col>
            </Col>
          ) : (
            <Col md="6" className="bg-dark bg d-none d-md-flex flex-column justify-content-center">
              <div className="text-center">
                <h1 className="text-white font-weight-normal">{t('identify_the_blind_spots_of')}</h1>
                <h1
                  className="text-primary text-uppercase font-weight-bold display-5 font-uppercase">{t('your_current_defense')}<br/>{t('infrastructure')}
                </h1>
              </div>
            </Col>
          )
        }
      </Container>
    </div>
  )
};
export default LoginLayout;
