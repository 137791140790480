import React, {useState} from 'react';
import queryString from "query-string";
import {Redirect} from "react-router-dom";
import SessionService from "../../api/SessionService";

const MspRedirection = ({history, location}) => {
  const [mspToken] = useState(queryString.parse(location.search)['msp_auth_token']);
  const [supervisedCompany] = useState(queryString.parse(location.search)['supervised-company']);
  const [isValidToken, setValidToken] = useState(true);
  const {referrer, search} = location.state ? location.state : {};

  if (mspToken && supervisedCompany) {
    SessionService.signInWithToken(mspToken, supervisedCompany)
      .then((res) => {
        setValidToken(res)
        if (referrer) {
          history.push({
            pathname: referrer,
            search
          });
        } else {
          history.push('/');
        }
      })
  }

  if (!mspToken || !isValidToken) {
    return <Redirect to='/'/>
  }

  return <></>
}

export default MspRedirection;
