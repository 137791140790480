export const openDocsByIdUrl = (id, url) => {
  if (id && window.ZohoHCAsap && window.ZohoHCAsap.Action) {
    window.ZohoHCAsap.kbArticles.view({articleId: id});
  } else {
    window.open(url, '_blank');
  }
}

export const openDocTab = (tabName, fallbackUrl) => {
  if (window.ZohoHCAsap && window.ZohoHCAsap.Action) {
    window.ZohoHCAsap.Action('open', {tab: tabName});
  } else {
    window.open(fallbackUrl, '_blank');
  }
}
