import React, {createContext, useContext, useState} from 'react';
import Session from "../../api/SessionService";

const SessionContext = createContext(null);

export const useSessionContext = () => {
  return useContext(SessionContext);
}

export const SessionProvider = ({children}) => {
  const registeredUser = Session.getRegisteredUser() || {};
  const {company, ...user} = registeredUser
  const authenticationHeaders = registeredUser.authenticationHeaders;
  const authenticationToken = registeredUser.authenticationToken;
  const [session, setSessionData] = useState({
    user,
    company,
    authenticationHeaders,
    authenticationToken,
    isSessionActive: true
  })

  return (
    <SessionContext.Provider value={{session, setSessionData}}>
      {children}
    </SessionContext.Provider>
  )
}
